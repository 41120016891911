import {ApplicationRef, DoBootstrap, LOCALE_ID, NgModule} from "@angular/core";
import {FlexModule} from "@angular/flex-layout";
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from "@angular/material-moment-adapter";
import {MatButtonModule} from "@angular/material/button";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MatDialogModule} from "@angular/material/dialog";
import {MatIconModule} from "@angular/material/icon";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatToolbarModule} from '@angular/material/toolbar';
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {
  AngularUiPowerupsModule,
  DIALOG_INTERACTION_SERVICE_DEFAULTS,
  DialogInteractionContextFactory,
  ModalInteractionContextFactory,
  PlaceModule
} from "@coolon/angular-ui-powerups";
import {Logger} from "@coolon/pectin";
import {ImgixAngularModule} from "@imgix/angular";
import {
  CommonModule as SpaCommonModule,
  CONTACT_DETAILS,
  CONTACT_FORM_CONFIGURATION, ContactFormConfiguration,
  SeoService
} from '@spa/common';
import {setAutoFreeze} from "immer";
import {environment} from "../environments/environment";
import {AppRoutingModule} from "./app-routing.module";
import {AppComponent} from "./app.component";
import {ComponentsModule} from "./components/components.module";
import {I18n} from "./i18n";

// Immer version 7 is barfing with this error https://github.com/immerjs/immer/issues/537.
setAutoFreeze(false);
Logger.logToConsole();

const PHONE_NUMBER = "0417 822 986";
const EMAIL_ADDRESS = "rachel@rachelpietschvocalstudio.com.au";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatDialogModule,
    AppRoutingModule,
    PlaceModule,
    AngularUiPowerupsModule,
    FlexModule,
    MatToolbarModule,
    SpaCommonModule,
    ComponentsModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    ImgixAngularModule.forRoot({
      domain: 'rachel-pietsch-vocal-studio.imgix.net',
      defaultImgixParams: {
        // This enables the auto format and compress imgix parameters by default for all images, which we recommend to reduce image size, but you might choose to turn this off.
        auto: 'format,compress',
      },
      /* Add more imgix config here, see the API section for a full list of options */
    }),
  ],
  providers: [
    {
      provide: CONTACT_FORM_CONFIGURATION, useValue: <ContactFormConfiguration>{
        submissionEndpoint: "//usebasin.com/f/3a8e110b9ab8",
        enableFormSubmission: environment.production,
        phoneNumber: PHONE_NUMBER,
        emailAddress: EMAIL_ADDRESS,
        confirmationMessage: I18n.places.contact.messages.confirmationMessage(),
        honeyPot: "alternateEmail",
        recaptchaSiteKey: '6Lew3SMUAAAAAJ82QoS7gqOTkRI_dhYrFy1f7Sqy' // Usebasic recpatcha key
      }
    },
    {
      provide: CONTACT_DETAILS, useValue: {
        email: EMAIL_ADDRESS,
        phoneNumber: PHONE_NUMBER,
        address: "50 Lincoln Rd, Croydon Victoria"
      }
    },
    {provide: DIALOG_INTERACTION_SERVICE_DEFAULTS, useValue: {restoreFocus: true}},
    {provide: ModalInteractionContextFactory, useClass: DialogInteractionContextFactory},
    {provide: LOCALE_ID, useValue: 'en-AU'},
    {provide: MAT_DATE_LOCALE, useExisting: LOCALE_ID},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
  bootstrap: []
})
export class AppModule implements DoBootstrap {

  constructor(seoService: SeoService) {
    seoService.configure({
        canonicalUrlBase: "https://www.rachelpietschvocalstudio.com.au",
        siteName: "Rachel Pietsch Vocal Studio",
        pathSeparator: " | "
      }
    )
  }

  ngDoBootstrap(appRef: ApplicationRef): void {
    appRef.bootstrap(AppComponent)

    // this.authService
    //     .init()
    //     .then((authorised) => {
    //         if (authorised) {
    //             this.document.body.classList.add("ready")
    //         }
    //         console.log('[ngDoBootstrap] cognito initialised, starting app.');
    //         appRef.bootstrap(AppComponent);
    //     })
    //     .catch(error => {
    //         this.document.body.classList.add("auth-failure")
    //         console.error('[ngDoBootstrap] cognito initialisation failed', error);
    //     });
  }
}
