<rps-page-title title="About Rachel"></rps-page-title>
<spa-common-page-slice>
  <section spa-common-right-sidebar>
    <p class="text-xl mb-8">
      Rachel is the owner and director of Rachel Pietsch Vocal Studio.
    </p>
    
    <ng-container *spaLibMarkdown>
      ### Education

      Rachel has been teaching piano since 1992 and singing since 1995. She studied to be a **classroom music teacher at Melbourne University** where she gained a Bachelor of Education (B.Ed), training in Kodaly (Level 1) and **majored in both Piano and Voice**. Here Rachel also had the opportunity to perform as solo vocalist with the Gryphon Big Band and the University of Melbourne Big Band. 
      
      She started studying **Speech Level Singing** in 2008, and joined the **IVA** (Institute for Vocal Advancement) when it began in 2013, where she is now an **Advanced Instructor**. Rachel continues to update and develop her skills and qualifications by attending a wide variety of workshops and conferences each year.

      ### Teaching

      After several years teaching music in the classroom (including choral and musical work) Rachel pursued teaching piano and voice privately. **She has worked with various educational institutions** as a piano and singing teacher, has been **invited to speak at various workshops** on topics such as vocal technique and developing contemporary piano skills, plus many years volunteering in church worship teams as vocal/choir director, worship leader and band member. 

      She now devotes a great deal of her time to her own studio, teaching and helping others learn, grow in confidence and achieve their goals, which Rachel is highly passionate about. **She is sought after for getting results and her teaching style**, which is centered around not only the development of good technique but also tuning in to her students' needs and pace intuitively and creatively, as she believes everyone learns and expresses themselves differently.

      **Rachel's clients** include a broad range of interests and needs, including working musicians and singers, recording artists, songwriters and composers, tribute artists, musical theatre performers, exam and audition preparation, voice rehabilitation (particularly singers who have performed for many years untrained) and beginners - with whom she loves discovering potential! 

      ### Performing

      At the age of 12 Rachel joined the church choir where she quickly developed her ear and harmony singing skills. By the age of 16 she was regularly playing piano for church services and had begun singing lead and back up, which was instrumental in furthering Rachel's passion and **skill development in many contemporary styles** over the years. 

      Rachel eventually broadened her performing to include singing and playing piano at weddings, restaurants, fundraisers, and piano accompaniment work, **covering a variety of roles and genres**.  Rachel is also a member of comedic tribute trio The Frock Tops.

      And lastly, and certainly by no means least, Rachel is a happy member of a loving family of 4 - hubby Andrew and 2 fur babies Lily and Leo (meow!)
    </ng-container>
    
    <section sidebar>
      <spa-common-image-frame [imageRef]="Images.RACHEL_PIETSCH_HEADSHOT"></spa-common-image-frame>
    </section> 
  </section>
</spa-common-page-slice>

