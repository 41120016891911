import {ClipboardModule} from "@angular/cdk/clipboard";
import {CommonModule as AngularCommon} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {AngularUiPowerupsModule} from "@coolon/angular-ui-powerups";
import {ImgixAngularModule} from "@imgix/angular";
import {NgxCaptchaModule} from "ngx-captcha";
import {ContactDetailsComponent} from "./components/contact-details/contact-details.component";
import {ContactFormComponent} from "./components/contact-form/contact-form.component";
import {InputComponent} from "./components/contact-form/input/input.component";
import {TextAreaComponent} from "./components/contact-form/text-area/text-area.component";
import {EmailAddressComponent} from "./components/email-address/email-address.component";
import {ImageFrameComponent} from "./components/image-frame/image-frame.component";
import {FourColsComponent} from "./components/layout/four-columns/four-cols.component";
import {RightSidebarComponent} from "./components/layout/right-sidebar/right-sidebar.component";
import {ThreeColsComponent} from "./components/layout/three-columns/three-cols.component";
import {TwoColumnsComponent} from "./components/layout/two-columns/two-columns.component";
import {MarkdownDirective} from "./components/markdown/markdown.directive";
import {MobileSideNavComponent} from './components/nav/mobile-side-nav/mobile-side-nav.component';
import {NavBarComponent} from './components/nav/nav-bar/nav-bar.component';
import {NavStackComponent} from './components/nav/nav-stack/nav-stack.component';
import {NavSubMenuComponent} from "./components/nav/nav-sub-menu/nav-sub-menu.component";
import {PlaceLinkActiveDirective} from './components/nav/place-link-active.directive';
import {PlaceLinkComponent} from "./components/nav/place-link/place-link.component";
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {PageSliceComponent} from './components/page-slice/page-slice.component';
import {PhoneNumberComponent} from "./components/phone-number/phone-number.component";
import {
  QuickContactFormActivityComponent
} from './components/quick-contact-form-activity/quick-contact-form-activity.component';
import {TestimonialComponent} from "./components/testimonial/testimonial.component";
import {MarkdownService} from "./services/markdown/markdown.service";
import {SeoService} from "./services/seo.service";

@NgModule({
  imports: [
    AngularCommon,
    MatMenuModule,
    AngularUiPowerupsModule,
    MatIconModule,
    FormsModule,
    ImgixAngularModule,
    MatButtonModule,
    NgxCaptchaModule,
    ClipboardModule,
    MatSnackBarModule
  ],
  declarations: [
    PageSliceComponent,
    PlaceLinkComponent,
    NavBarComponent,
    NavSubMenuComponent,
    NavStackComponent,
    ImageFrameComponent,
    TwoColumnsComponent,
    RightSidebarComponent,
    ThreeColsComponent,
    PlaceLinkActiveDirective,
    PhoneNumberComponent,
    EmailAddressComponent,
    TestimonialComponent,
    ContactFormComponent,
    TextAreaComponent,
    InputComponent,
    QuickContactFormActivityComponent,
    ContactDetailsComponent,
    MarkdownDirective,
    MobileSideNavComponent,
    PageNotFoundComponent,
    FourColsComponent
  ],
  exports: [
    PageSliceComponent,
    PlaceLinkComponent,
    NavBarComponent,
    NavStackComponent,
    NavSubMenuComponent,
    ImageFrameComponent,
    TwoColumnsComponent,
    RightSidebarComponent,
    ThreeColsComponent,
    PlaceLinkActiveDirective,
    PhoneNumberComponent,
    EmailAddressComponent,
    TestimonialComponent,
    ContactFormComponent,
    TextAreaComponent,
    InputComponent,
    ContactDetailsComponent,
    MarkdownDirective,
    MobileSideNavComponent,
    PageNotFoundComponent,
    FourColsComponent
  ],
  providers: [
    SeoService,
    MarkdownService
  ]
})
export class CommonModule {
}
