import {
  Directive,
  ElementRef, EmbeddedViewRef,
  Inject,
  Input,
  OnInit,
  Optional,
  PLATFORM_ID,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import {DisposableComponent} from "@coolon/angular-ui-powerups";
import {Dsl, lang, StoreInValueModel, TrackExternal, ValueModel} from '@coolon/pectin';
import {isEmpty} from "lodash-es";
import {MarkdownService} from '../../services/markdown/markdown.service';

// TODO: Look at using unified parser:
//  https://javascript.plainenglish.io/markdown-to-angular-rendering-using-unified-and-remark-96835bb877
//  or https://markdoc.io/docs/getting-started


/**
 * @example
 * <ng-container *aup-markdown [content]="..."></aup-markdown>
 * <aup-markdown [content$]="stringValueModel$"></aup-markdown>
 * <aup-markdown ngPreserveWhitespaces>
 *   # This is markdown content
 *
 *  With a list
 *    - a
 *    - b
 * </aup-markdown>
 */
@Directive({
  selector: '[spaLibMarkdown]'
})
export class MarkdownDirective extends DisposableComponent implements OnInit {

  @Input()
  @TrackExternal()
  markdown$: ValueModel<string>;

  @Input()
  @StoreInValueModel('markdown$')
  markdown: string;

  @Input()
  inline = false;

  private readonly renderStrategy: MarkdownDirective.RenderStrategy;

  constructor(
    @Optional() private readonly templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private readonly markdownService: MarkdownService,
    @Inject(PLATFORM_ID) private platform: Object) {
    super();
    this.renderStrategy = this.createInsertionStrategy(templateRef);
  }

  ngOnInit() {
    const {when} = Dsl.disposeWith(this);
    when(this.markdown$).changes(content => this.renderContent(content), {initialValue: 'triggerChangeUnlessNil'});
    this.renderContent(this.markdown);
  }

  createInsertionStrategy(templateRef: TemplateRef<any>): MarkdownDirective.RenderStrategy {
    if (templateRef) {
      const view = this.templateRef.createEmbeddedView(null);
      let nodes = view.rootNodes;
      if (nodes.length === 2) {
        if (nodes[0].nodeType === Node.COMMENT_NODE && nodes[1].nodeType === Node.TEXT_NODE) {
          return new MarkdownDirective.NgContainerChildInjector(this.markdownService, nodes[1].textContent);
        } else {
          console.log(nodes)
          lang.notImplemented("Multiple nodes that are not a comment node followed by a text node");
        }
      } else {
        return new MarkdownDirective.ElementTemplateInjector(this.markdownService, templateRef, nodes[0].textContent);
      }
    } else {
      return new MarkdownDirective.InnerHtmlInjector(this.markdownService);
    }
  }

  private renderContent(content: string) {
    this.renderStrategy.renderContent(this.viewContainer, content, this.inline);
  }
}

export namespace MarkdownDirective {
  // 4 scenarios
  // <div *spaLibMarkdown content=".."></div>
  // <div *spaLibMarkdown>**blah**</div>
  // <ng-container *spaLibMarkdown>**blah**</ng-container>
  // <ng-container *spaLibMarkdown content="..."></ng-container>
  export abstract class RenderStrategy {
    constructor(protected readonly markdownService: MarkdownService) {
    }

    abstract renderContent(viewContainer: ViewContainerRef, markdown: string, inline: boolean): void;

    protected parseMarkdown(markdown: string, inline: boolean) {
      return inline ?
        this.markdownService.renderInline(this.normaliseTextContent(markdown)) :
        this.markdownService.render(this.normaliseTextContent(markdown));
    }

    protected normaliseTextContent(content: string) {
      const lines = content.split('\n');
      const prefix = lines.reduce((minPrefix, line) => {
        const leadingWhitespace = line.search(/\S/);
        return leadingWhitespace < 0 ? minPrefix : Math.min(minPrefix, leadingWhitespace);
      }, Number.MAX_SAFE_INTEGER);
      return lines.map(l => l.substr(prefix)).join('\n');
    }

  }

  export class InnerHtmlInjector extends RenderStrategy {
    override renderContent(viewContainer: ViewContainerRef, markdown: string, inline: boolean): void {
      viewContainer.element.nativeElement.innerHTML = this.parseMarkdown(markdown, inline);
    }
  }

  export class ElementTemplateInjector extends RenderStrategy {

    constructor(markdownService: MarkdownService, private readonly template: TemplateRef<any>, private readonly templateContent: string) {
      super(markdownService);
    }

    renderContent(viewContainer: ViewContainerRef, markdown: string, inline: boolean): void {
      const contentElement = viewContainer.createEmbeddedView(this.template)
      let rootNode = contentElement.rootNodes[0];
      let renderInline = inline || rootNode.nodeName === "P";
      const innerHtml = this.parseMarkdown(isEmpty(markdown) ? this.templateContent : markdown, renderInline);
      rootNode.innerHTML = innerHtml;
    }

  }

  export class NgContainerChildInjector extends RenderStrategy {

    constructor(markdownService: MarkdownService, private readonly templateContent: string) {
      super(markdownService);
    }

    renderContent(viewContainer: ViewContainerRef, markdown: string, inline: boolean): void {
      const innerHtml = this.parseMarkdown(isEmpty(markdown) ? this.templateContent : markdown, inline);
      let hostElement = viewContainer.element.nativeElement;
      let parentElement = hostElement.parentElement as HTMLElement;

      const template = document.createElement('template') as HTMLTemplateElement;
      // console.log({hostElement, parentElement, markdown});
      template.innerHTML = innerHtml;
      parentElement.insertBefore(template.content, hostElement);
    }

  }
}
