<p>
  <b>Email:</b><br>
  <a [spaCommonEmailAddress]="contactDetails.email"></a>
</p>
<p>
  <b>Phone:</b><br>
  <a [spaCommonPhoneNumber]="contactDetails.phoneNumber"></a>
</p>
<p>
  <b>Address:</b><br>
  <aup-markdown [content]="contactDetails.address" [inline]="true"></aup-markdown>
</p>
