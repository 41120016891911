import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { DisposableComponent } from '@coolon/angular-ui-powerups';
import {
  DirtyTrackingValueModel,
  Dsl,
  Form,
  TrackExternal,
  ValueModel,
  ValueModelChain,
} from '@coolon/pectin';

@Component({
  selector: 'app-abstratin-input',
  template: '',
})
export class AbstractInputComponent extends DisposableComponent {
  @Input()
  name: string;

  @Input()
  type: string = 'text';

  @Input()
  label: string;

  @Input()
  @TrackExternal()
  valueModel: DirtyTrackingValueModel<string>;
  readonly error: ValueModelChain<string>;
  readonly _hasError: ValueModel<boolean>;

  constructor() {
    super();
    const { chain } = Dsl.disposeWith(this);
    this.error = chain(this.valueModel.errorList).first();
    this._hasError = chain.stringValue(this.error).isNotBlank();
  }

  @HostBinding('class.error')
  get hasError() {
    return this._hasError.value;
  }

  get disabled():boolean {
    return !this.valueModel.enabled.value
  }
}
