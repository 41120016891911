import {Component, OnInit} from '@angular/core';
import {Place, PlaceComponent, PlaceRouter,} from '@coolon/angular-ui-powerups';
import {Dsl} from '@coolon/pectin';
import {I18n} from '../../i18n';
import {Images} from '../../services/images/image-list';
import {placeDestinations} from "../place-destinations";

@Place({
  path: 'singing-lessons',
  data: {
    nav: I18n.places.singingLessons.nav,
    seo: I18n.places.singingLessons.seo,
  },
  authenticate: true
})
@Component({
  selector: 'rps-singing-lessons',
  templateUrl: './singing-lessons.component.html',
  styleUrls: ['./singing-lessons.component.scss'],
})
export class SingingLessonsComponent extends PlaceComponent {

  readonly i18n = I18n.places.singingLessons;

  readonly Images = Images;

  readonly testimonials = [
    this.i18n.testimonials.philPitt,
  ];

  readonly ivaHref = [
    placeDestinations.about.pathSegment,
    placeDestinations.ivaTechnique.pathSegment
  ].join("/");

  readonly testimonialsHref = placeDestinations.testimonials.pathSegment;

  constructor(private readonly placeRouter: PlaceRouter) {
    super();
    const {chain} = Dsl.disposeWith(this);
  }

}
