import {Directive, HostBinding, Input} from '@angular/core';
import {PlaceDefinition, PlaceRouter} from "@coolon/angular-ui-powerups";
import {StoreInValueModel, TrackExternal, ValueModel} from "@coolon/pectin";
import {AbstractNavLinkComponent} from "./abstract-nav-link.component";

@Directive({
  selector: '[spaCommonPlaceLinkActive]',
  host: {
    class: "place-link"
  },
})
export class PlaceLinkActiveDirective extends AbstractNavLinkComponent {

  @Input()
  @StoreInValueModel("placeDefinition$")
  spaCommonPlaceLinkActive: PlaceDefinition;

  constructor(placeRouter: PlaceRouter) {
    super(placeRouter)
  }

  @HostBinding("class.active")
  get active() {
    return this.active$.value
  }

}
