import { Component, OnInit } from '@angular/core';
import { Dsl } from '@coolon/pectin';
import {
  Place,
  PlaceComponent,
} from '@coolon/angular-ui-powerups';
import {ImageRef, Testimonial} from "@spa/common";
import {chunk} from "lodash-es";
import { I18n } from '../../i18n';
import {Images} from "../../services/images/image-list";

@Place({
  path: 'student-concerts',
  data: {
    nav: I18n.places.studentConcerts.nav,
    seo: I18n.places.studentConcerts.seo
  },
  authenticate: true
})
@Component({
  selector: 'rps-student-concerts',
  templateUrl: './student-concerts.component.html',
  styleUrls: ['./student-concerts.component.scss'],
})
export class StudentConcertsComponent extends PlaceComponent implements OnInit {

  readonly i18n = I18n.places.studentConcerts;
  readonly testimonials: Testimonial[];
  readonly images: ImageRef[];
  galleryItems: any[];
  readonly imageRows: ImageRef[][];

  constructor() {
    super();
    const {chain} = Dsl.disposeWith(this);

    this.testimonials = Object.keys(this.i18n.testimonials)
      .map(key => this.i18n.testimonials[key]);

    this.images= Object.keys(Images.CONCERTS).map(key => {
      return Images.CONCERTS[key];
    });

    this.imageRows = chunk(this.images, 3).map(chunk => {
      switch(chunk.length) {
        case 1: return chunk.concat([undefined, undefined])
        case 2: return chunk.concat([undefined])
        default: return chunk
      }
    });

    // this.galleryItems = [];
    // const groupSize = this.testimonials.length;
    //
    // this.testimonials.forEach((t, index) => {
    //     this.galleryItems.push(t);
    //   this.images.slice(index, index + groupSize).forEach(image => this.galleryItems.push(image));
    // })
    //
    // console.log(this.galleryItems);
  }

  ngOnInit() {
  }

  isImage(item: any): item is ImageRef {
    return item instanceof ImageRef;
  }

  isTestimonial(item: any): item is Testimonial {
    return !this.isImage(item); // hack for now.
  }
}
