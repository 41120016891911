<div class="backdrop" *ngIf="mobileNavOpen$.value"></div>
<nav class="mobile-nav" [style]="{visibility:mobileNavOpen$.value ? 'visible' : 'hidden'}">
  <div class="nav-draw" *ngIf="mobileNavOpen$.value" @slideRightOnEnter>
    <span class="mobile-nav-header">
      <span>Menu</span>
      <button mat-icon-button (click)="mobileNavOpen$.value = false"><mat-icon>close</mat-icon></button>
    </span>
    <section class="mobile-nav-item-container">
      <nav spa-common-nav-stack [places]="places"></nav>
    </section>
  </div>
</nav>
