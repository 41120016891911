import {Component, HostBinding, HostListener} from '@angular/core';
import {PlaceDefinition, PlaceRouter, Visit} from "@coolon/angular-ui-powerups";
import {BooleanValueModelChain, Dsl, ListModelChain} from "@coolon/pectin";
import {AbstractNavLinkComponent} from "../abstract-nav-link.component";

@Component({
  selector: 'a[spa-common-place-link]',
  templateUrl: './place-link.component.html',
  styleUrls: ['./place-link.component.scss'],
  host: {
    class:"place-link"
  },
})
export class PlaceLinkComponent extends AbstractNavLinkComponent {

  readonly childPlaces$: ListModelChain<PlaceDefinition>;
  private parent$: BooleanValueModelChain;

  constructor(placeRouter: PlaceRouter) {
    super(placeRouter)
    const {chain} = Dsl.disposeWith(this);
    this.childPlaces$ = chain(this.placeDefinition$).pluckList("children");
    this.parent$ = chain(this.childPlaces$).isNotEmpty();
  }

  @HostBinding("class.active")
  get active() {
    return this.active$.value
  }

  @HostBinding("class.place-link-parent")
  get parent() {
    return this.parent$.value
  }

  @HostBinding("attr.href")
  get href() {
    return this.placePath$.value;
  }

  @HostListener("click", ['$event'])
  onClick($event: Event) {
    this.navigate($event, ...this.placeDefinitions$.value)
  }

}
