<rps-page-header [places]="places"></rps-page-header>
<rps-mobile-page-header [toggleMenuCommand]="mobileNav.toggleMenuCommand"></rps-mobile-page-header>
<aup-place-outlet #outlet
                  [animate]="false"
                  [placeAnimationOrder]="places"></aup-place-outlet>
<rps-page-footer></rps-page-footer>
<span class="mobile-menu-toggle-container">
    <button mat-icon-button [pectinCommand]="mobileNav.toggleMenuCommand"><mat-icon>menu</mat-icon></button>
</span>
<spa-common-mobile-side-nav #mobileNav [places]="places"></spa-common-mobile-side-nav>


