<label for="{{name}}">
  {{label}}
  <span class="error" *ngIf="error.value"> - {{ error.value }}</span></label>
<input
  id="{{name}}"
  type="{{type}}"
  name="{{name}}"
  [(ngModel)]="valueModel.value"
  [pectinFocusBinding]="valueModel.focusContext"
  [disabled]="disabled"
/>
