import { Component, OnInit } from '@angular/core';
import {BooleanValueModelChain, Dsl, MutableValueModel} from '@coolon/pectin';
import {
  Place,
  PlaceComponent,
} from '@coolon/angular-ui-powerups';
import { I18n } from '../../i18n';

@Place({
  path: 'contact',
  data: {
    nav: I18n.places.contact.nav,
    seo: I18n.places.contact.seo
  },
  authenticate: true
})
@Component({
  selector: 'rps-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent extends PlaceComponent {

  readonly i18n = I18n.places.contact;

  readonly finished = new MutableValueModel<boolean>(false);
  readonly error = new MutableValueModel<any>();
  readonly showForm: BooleanValueModelChain;

  constructor() {
    super();
    const {chain, valueOf} = Dsl.disposeWith(this);

    this.showForm = valueOf.all(
      this.finished,
      chain(this.error).isNotNil()
    ).areFalsey()
  }

  done() {
    this.finished.value = true;
  }

  failed(e: any) {
    this.error.value = e;
  }
}
