import {Component, HostListener, Inject} from '@angular/core';
import {DisposableComponent, InteractionService} from '@coolon/angular-ui-powerups';
import {Command, Dsl} from '@coolon/pectin';
import {CONTACT_FORM_CONFIGURATION, ContactFormConfiguration, QuickContactFormActivityComponent} from "@spa/common";
import {I18n} from '../../i18n';

@Component({
  selector: 'rps-call-to-action-buttons, [rpsCallToActionButtons]',
  templateUrl: './call-to-action-buttons.component.html',
  styleUrls: ['./call-to-action-buttons.component.scss'],
})
export class CallToActionButtonsComponent extends DisposableComponent {

  readonly i18n = I18n.components.callToActionButtons;

  readonly quickContactCommand: Command<void>;

  constructor(@Inject(CONTACT_FORM_CONFIGURATION) readonly contactConfiguration: ContactFormConfiguration,
              interactionService: InteractionService) {
    super();
    const {chain} = Dsl.disposeWith(this);

    const interactionContext = interactionService.createModalInteractionContextDisposedWith(this);

    this.quickContactCommand = Command.create(() => {
      interactionContext.startActivity(QuickContactFormActivityComponent);
    })
  }

}
