import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {Dsl, Command, StoreInValueModel, TrackExternal, ValueModel} from '@coolon/pectin';
import { DisposableComponent } from '@coolon/angular-ui-powerups';
import { I18n } from '../../i18n';

@Component({
  selector: 'spa-common-page-slice',
  templateUrl: './page-slice.component.html',
  styleUrls: ['./page-slice.component.scss'],
})
export class PageSliceComponent extends DisposableComponent implements OnInit {

  readonly i18n = I18n.components.pageSlice;

  @Input()
  @HostBinding("attr.padding")
  padding: "default" | "none" = "default";

  @Input()
  sliceContentClass: string = "slice-content";

  @Input()
  sliceFooterClass: string = "slice-footer";

  constructor() {
    super();
    const {chain} = Dsl.disposeWith(this);
  }

  ngOnInit() {
  }

}
