// TODO: migrate to markdown-it and copy this plugin: https://github.com/markdown-it/markdown-it-sub/blob/master/index.js
import { marked } from 'marked';
import RendererThis = marked.RendererThis;
import TokenizerThis = marked.TokenizerThis;
import Tokens = marked.Tokens;

export const markdownPillExtension = (renderPill: (text: string) => string) => {
  return {
    name: 'aup-pill',
    level: 'inline',
    start: (src: string) => {
      const index = src.match(/\(\([^)]/)?.index;
      // if (index) {
      //     console.log({start:src})
      // }
      return index;
    },
    tokenizer: function(this: TokenizerThis, src: string) {
      const rule = /^(\(\((([^)])+)\)\))/;  // Regex for the complete token
      const match = rule.exec(src);
      if (match) {
        const token = {
          type: 'aup-pill',          // Should match "name" above
          raw: match[0],         // Text to consume from the source
          text: match[2].trim(), // Additional custom properties
          // tokens: []              // Array where child inline tokens will be generated
        };
        // console.log(token)
        // this.lexer.inlineTokens(token.text, token.tokens);    // Queue this data to be processed for inline tokens
        return token;
      } else {
        return undefined;
      }
    },
    renderer: function(this: RendererThis, token: Tokens.Generic) {
      // @ts-ignore (until typings are up to date)
      return renderPill(token.text);
    }

  };
};
