import {Component, HostBinding, HostListener} from "@angular/core";
import {
  DisposableComponent,
  PlaceComponent,
  PlaceRouter,
  slideRightOnEnterAnimation
} from "@coolon/angular-ui-powerups";
import {Constructable, Dsl, MutableValueModel} from "@coolon/pectin";
import {isScullyRunning} from "@scullyio/ng-lib";
import {SeoService} from "@spa/common";
import {slideInLeftAnimation} from "angular-animations";
import {AboutComponent} from "./places/about/about.component";
import {ContactComponent} from "./places/contact/contact.component";
import {HomeComponent} from "./places/home/home.component";
import {PricingAndPoliciesComponent} from "./places/pricing-and-policies/pricing-and-policies.component";
import {SingingLessonsComponent} from "./places/singing-lessons/singing-lessons.component";
import {SpeechTrainingComponent} from "./places/speech-training/speech-training.component";
import {StudentConcertsComponent} from "./places/student-concerts/student-concerts.component";
import {TestimonialsComponent} from "./places/testimonials/testimonials.component";

@Component({
  selector: "rachel-pietsch-studios-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  animations: [
    slideRightOnEnterAnimation
  ]
})
export class AppComponent extends DisposableComponent {

  readonly places: Constructable<PlaceComponent>[] = [
    HomeComponent,
    SingingLessonsComponent,
    // SpeechTrainingComponent,
    TestimonialsComponent,
    StudentConcertsComponent,
    // PricingAndPoliciesComponent,
    AboutComponent,
    ContactComponent
  ]

  readonly mobileNavOpen$ = new MutableValueModel<boolean>(false);

  constructor() {
    super();
    const {chain, when} = Dsl.disposeWith(this);
  }

  @HostBinding("class.scully-build")
  get isScullyBuild() {
    return isScullyRunning();
  }

}
