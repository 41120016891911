/******** IMPORTANT *********
 * 
 * This file is automatically generated.  Any edits will be lost.
 * 
 ****/
/* tslint:disable:no-shadowed-variable */

import MessageFormat from 'message-format';
import {isObject, isEmpty, isNumber, isNaN} from 'lodash-es';
import {Alert} from '@coolon/angular-ui-powerups';
import {Confirm} from '@coolon/angular-ui-powerups';
import {NavItem} from '@coolon/angular-ui-powerups';
import {CommandFace} from '@coolon/angular-ui-powerups';
import {ColumnTextProperties} from '@coolon/angular-ui-powerups';
import {PageSeo} from '@spa/common';
import {Testimonial} from '@spa/common';
import defaultBundle from './locales/en.json';
import messageKeysBundle from './locales/message-keys.json';

const _messageLocaleKey = "message-keys";
export namespace I18n {
   let _locale:string = null;
   let _activeBundle:any = null;
   const _bundles = new Map<string,any>();
   registerBundle(_messageLocaleKey, messageKeysBundle);
   registerBundle("en", defaultBundle);
   setLocale("en");
   export function registerBundle(locale:string, bundle:any):void {
      if (_bundles.has(locale)) { throw new Error("bundle already registered for: " + locale)}
      _bundles.set(locale, bundle);
   }
   export function setLocale(locale:string):void {
      if (!_bundles.has(locale)) { throw new Error("No bundle registered for: " + locale)}
      _locale = locale;
      _activeBundle = _bundles.get(_locale);
   }
   export function getAvailableLocales():string[] {
      return Array.from(_bundles.keys()).filter(key => key !== _messageLocaleKey);
   }
   export function getLocale():string {
      return _locale
   }
   export namespace components {
      export namespace callToActionButtons {
         export function name():string {
            try {
               const translation = _activeBundle.components.callToActionButtons.name;
               if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                  return "missing key " + "components.callToActionButtons.name";
               } else { 
                  return translation;
               }
            } catch (error) { 
               return "missing key " + "components.callToActionButtons.name";
            }
         }
      }
      export namespace mobilePageHeader {
         export function name():string {
            try {
               const translation = _activeBundle.components.mobilePageHeader.name;
               if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                  return "missing key " + "components.mobilePageHeader.name";
               } else { 
                  return translation;
               }
            } catch (error) { 
               return "missing key " + "components.mobilePageHeader.name";
            }
         }
      }
      export namespace pageFooter {
         export namespace pageHeader {
            export function name():string {
               try {
                  const translation = _activeBundle.components.pageFooter.pageHeader.name;
                  if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                     return "missing key " + "components.pageFooter.pageHeader.name";
                  } else { 
                     return translation;
                  }
               } catch (error) { 
                  return "missing key " + "components.pageFooter.pageHeader.name";
               }
            }
         }
      }
      export namespace pageHeader {
         export function name():string {
            try {
               const translation = _activeBundle.components.pageHeader.name;
               if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                  return "missing key " + "components.pageHeader.name";
               } else { 
                  return translation;
               }
            } catch (error) { 
               return "missing key " + "components.pageHeader.name";
            }
         }
      }
      export namespace pageTitle {
         export function name():string {
            try {
               const translation = _activeBundle.components.pageTitle.name;
               if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                  return "missing key " + "components.pageTitle.name";
               } else { 
                  return translation;
               }
            } catch (error) { 
               return "missing key " + "components.pageTitle.name";
            }
         }
      }
   }
   export namespace places {
      export namespace about {
         export const nav = {
            title():string {
               try {
                  const translation = _activeBundle.places.about.nav.title;
                  if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                     return "missing key " + "places.about.nav.title";
                  } else { 
                     return translation;
                  }
               } catch (error) { 
                  return "missing key " + "places.about.nav.title";
               }
            }
         } as NavItem;
         export namespace ivaTechnique {
            export const nav = {
               title():string {
                  try {
                     const translation = _activeBundle.places.about.ivaTechnique.nav.title;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "places.about.ivaTechnique.nav.title";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "places.about.ivaTechnique.nav.title";
                  }
               }
            } as NavItem;
         }
         export namespace rachel {
            export const nav = {
               title():string {
                  try {
                     const translation = _activeBundle.places.about.rachel.nav.title;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "places.about.rachel.nav.title";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "places.about.rachel.nav.title";
                  }
               }
            } as NavItem;
            export const seo = {
               title():string {
                  try {
                     const translation = _activeBundle.places.about.rachel.seo.title;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "places.about.rachel.seo.title";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "places.about.rachel.seo.title";
                  }
               },
               description():string {
                  try {
                     const translation = _activeBundle.places.about.rachel.seo.description;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "places.about.rachel.seo.description";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "places.about.rachel.seo.description";
                  }
               }
            } as PageSeo;
         }
      }
      export namespace contact {
         export const nav = {
            title():string {
               try {
                  const translation = _activeBundle.places.contact.nav.title;
                  if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                     return "missing key " + "places.contact.nav.title";
                  } else { 
                     return translation;
                  }
               } catch (error) { 
                  return "missing key " + "places.contact.nav.title";
               }
            }
         } as NavItem;
         export const seo = {
            description():string {
               try {
                  const translation = _activeBundle.places.contact.seo.description;
                  if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                     return "missing key " + "places.contact.seo.description";
                  } else { 
                     return translation;
                  }
               } catch (error) { 
                  return "missing key " + "places.contact.seo.description";
               }
            }
         } as PageSeo;
         export namespace messages {
            export function confirmationMessage():string {
               try {
                  const translation = _activeBundle.places.contact.messages.confirmationMessage;
                  if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                     return "missing key " + "places.contact.messages.confirmationMessage";
                  } else { 
                     return translation;
                  }
               } catch (error) { 
                  return "missing key " + "places.contact.messages.confirmationMessage";
               }
            }
         }
      }
      export namespace home {
         export const nav = {
            title():string {
               try {
                  const translation = _activeBundle.places.home.nav.title;
                  if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                     return "missing key " + "places.home.nav.title";
                  } else { 
                     return translation;
                  }
               } catch (error) { 
                  return "missing key " + "places.home.nav.title";
               }
            },
            icon():string {
               try {
                  const translation = _activeBundle.places.home.nav.icon;
                  if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                     return "missing key " + "places.home.nav.icon";
                  } else { 
                     return translation;
                  }
               } catch (error) { 
                  return "missing key " + "places.home.nav.icon";
               }
            },
            tooltip():string {
               try {
                  const translation = _activeBundle.places.home.nav.tooltip;
                  if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                     return "missing key " + "places.home.nav.tooltip";
                  } else { 
                     return translation;
                  }
               } catch (error) { 
                  return "missing key " + "places.home.nav.tooltip";
               }
            }
         } as NavItem;
         export const seo = {
            title():string {
               try {
                  const translation = _activeBundle.places.home.seo.title;
                  if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                     return "missing key " + "places.home.seo.title";
                  } else { 
                     return translation;
                  }
               } catch (error) { 
                  return "missing key " + "places.home.seo.title";
               }
            },
            description():string {
               try {
                  const translation = _activeBundle.places.home.seo.description;
                  if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                     return "missing key " + "places.home.seo.description";
                  } else { 
                     return translation;
                  }
               } catch (error) { 
                  return "missing key " + "places.home.seo.description";
               }
            }
         } as PageSeo;
      }
      export namespace pricingAndPolicies {
         export const nav = {
            title():string {
               try {
                  const translation = _activeBundle.places.pricingAndPolicies.nav.title;
                  if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                     return "missing key " + "places.pricingAndPolicies.nav.title";
                  } else { 
                     return translation;
                  }
               } catch (error) { 
                  return "missing key " + "places.pricingAndPolicies.nav.title";
               }
            },
            icon():string {
               try {
                  const translation = _activeBundle.places.pricingAndPolicies.nav.icon;
                  if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                     return "missing key " + "places.pricingAndPolicies.nav.icon";
                  } else { 
                     return translation;
                  }
               } catch (error) { 
                  return "missing key " + "places.pricingAndPolicies.nav.icon";
               }
            },
            tooltip():string {
               try {
                  const translation = _activeBundle.places.pricingAndPolicies.nav.tooltip;
                  if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                     return "missing key " + "places.pricingAndPolicies.nav.tooltip";
                  } else { 
                     return translation;
                  }
               } catch (error) { 
                  return "missing key " + "places.pricingAndPolicies.nav.tooltip";
               }
            }
         } as NavItem;
      }
      export namespace singingLessons {
         export const nav = {
            title():string {
               try {
                  const translation = _activeBundle.places.singingLessons.nav.title;
                  if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                     return "missing key " + "places.singingLessons.nav.title";
                  } else { 
                     return translation;
                  }
               } catch (error) { 
                  return "missing key " + "places.singingLessons.nav.title";
               }
            },
            tooltip():string {
               try {
                  const translation = _activeBundle.places.singingLessons.nav.tooltip;
                  if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                     return "missing key " + "places.singingLessons.nav.tooltip";
                  } else { 
                     return translation;
                  }
               } catch (error) { 
                  return "missing key " + "places.singingLessons.nav.tooltip";
               }
            }
         } as NavItem;
         export const seo = {
            title():string {
               try {
                  const translation = _activeBundle.places.singingLessons.seo.title;
                  if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                     return "missing key " + "places.singingLessons.seo.title";
                  } else { 
                     return translation;
                  }
               } catch (error) { 
                  return "missing key " + "places.singingLessons.seo.title";
               }
            },
            description():string {
               try {
                  const translation = _activeBundle.places.singingLessons.seo.description;
                  if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                     return "missing key " + "places.singingLessons.seo.description";
                  } else { 
                     return translation;
                  }
               } catch (error) { 
                  return "missing key " + "places.singingLessons.seo.description";
               }
            }
         } as PageSeo;
         export namespace testimonials {
            export const philPitt = {
               content():string {
                  try {
                     const translation = _activeBundle.places.singingLessons.testimonials.philPitt.content;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "places.singingLessons.testimonials.philPitt.content";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "places.singingLessons.testimonials.philPitt.content";
                  }
               },
               author():string {
                  try {
                     const translation = _activeBundle.places.singingLessons.testimonials.philPitt.author;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "places.singingLessons.testimonials.philPitt.author";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "places.singingLessons.testimonials.philPitt.author";
                  }
               }
            } as Testimonial;
            export const mitchGroves = {
               content():string {
                  try {
                     const translation = _activeBundle.places.singingLessons.testimonials.mitchGroves.content;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "places.singingLessons.testimonials.mitchGroves.content";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "places.singingLessons.testimonials.mitchGroves.content";
                  }
               },
               author():string {
                  try {
                     const translation = _activeBundle.places.singingLessons.testimonials.mitchGroves.author;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "places.singingLessons.testimonials.mitchGroves.author";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "places.singingLessons.testimonials.mitchGroves.author";
                  }
               }
            } as Testimonial;
         }
      }
      export namespace speechTraining {
         export const nav = {
            title():string {
               try {
                  const translation = _activeBundle.places.speechTraining.nav.title;
                  if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                     return "missing key " + "places.speechTraining.nav.title";
                  } else { 
                     return translation;
                  }
               } catch (error) { 
                  return "missing key " + "places.speechTraining.nav.title";
               }
            },
            tooltip():string {
               try {
                  const translation = _activeBundle.places.speechTraining.nav.tooltip;
                  if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                     return "missing key " + "places.speechTraining.nav.tooltip";
                  } else { 
                     return translation;
                  }
               } catch (error) { 
                  return "missing key " + "places.speechTraining.nav.tooltip";
               }
            }
         } as NavItem;
      }
      export namespace studentConcerts {
         export const nav = {
            title():string {
               try {
                  const translation = _activeBundle.places.studentConcerts.nav.title;
                  if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                     return "missing key " + "places.studentConcerts.nav.title";
                  } else { 
                     return translation;
                  }
               } catch (error) { 
                  return "missing key " + "places.studentConcerts.nav.title";
               }
            },
            icon():string {
               try {
                  const translation = _activeBundle.places.studentConcerts.nav.icon;
                  if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                     return "missing key " + "places.studentConcerts.nav.icon";
                  } else { 
                     return translation;
                  }
               } catch (error) { 
                  return "missing key " + "places.studentConcerts.nav.icon";
               }
            },
            tooltip():string {
               try {
                  const translation = _activeBundle.places.studentConcerts.nav.tooltip;
                  if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                     return "missing key " + "places.studentConcerts.nav.tooltip";
                  } else { 
                     return translation;
                  }
               } catch (error) { 
                  return "missing key " + "places.studentConcerts.nav.tooltip";
               }
            }
         } as NavItem;
         export const seo = {
            description():string {
               try {
                  const translation = _activeBundle.places.studentConcerts.seo.description;
                  if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                     return "missing key " + "places.studentConcerts.seo.description";
                  } else { 
                     return translation;
                  }
               } catch (error) { 
                  return "missing key " + "places.studentConcerts.seo.description";
               }
            }
         } as PageSeo;
         export namespace testimonials {
            export const two = {
               content():string {
                  try {
                     const translation = _activeBundle.places.studentConcerts.testimonials.two.content;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "places.studentConcerts.testimonials.two.content";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "places.studentConcerts.testimonials.two.content";
                  }
               }
            } as Testimonial;
            export const one = {
               content():string {
                  try {
                     const translation = _activeBundle.places.studentConcerts.testimonials.one.content;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "places.studentConcerts.testimonials.one.content";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "places.studentConcerts.testimonials.one.content";
                  }
               }
            } as Testimonial;
            export const three = {
               content():string {
                  try {
                     const translation = _activeBundle.places.studentConcerts.testimonials.three.content;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "places.studentConcerts.testimonials.three.content";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "places.studentConcerts.testimonials.three.content";
                  }
               }
            } as Testimonial;
            export const four = {
               content():string {
                  try {
                     const translation = _activeBundle.places.studentConcerts.testimonials.four.content;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "places.studentConcerts.testimonials.four.content";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "places.studentConcerts.testimonials.four.content";
                  }
               }
            } as Testimonial;
            export const five = {
               content():string {
                  try {
                     const translation = _activeBundle.places.studentConcerts.testimonials.five.content;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "places.studentConcerts.testimonials.five.content";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "places.studentConcerts.testimonials.five.content";
                  }
               }
            } as Testimonial;
            export const six = {
               content():string {
                  try {
                     const translation = _activeBundle.places.studentConcerts.testimonials.six.content;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "places.studentConcerts.testimonials.six.content";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "places.studentConcerts.testimonials.six.content";
                  }
               }
            } as Testimonial;
         }
      }
      export namespace testimonials {
         export const nav = {
            title():string {
               try {
                  const translation = _activeBundle.places.testimonials.nav.title;
                  if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                     return "missing key " + "places.testimonials.nav.title";
                  } else { 
                     return translation;
                  }
               } catch (error) { 
                  return "missing key " + "places.testimonials.nav.title";
               }
            }
         } as NavItem;
         export const seo = {
            description():string {
               try {
                  const translation = _activeBundle.places.testimonials.seo.description;
                  if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                     return "missing key " + "places.testimonials.seo.description";
                  } else { 
                     return translation;
                  }
               } catch (error) { 
                  return "missing key " + "places.testimonials.seo.description";
               }
            }
         } as PageSeo;
         export namespace testimonials {
            export const dellaHarris = {
               content():string {
                  try {
                     const translation = _activeBundle.places.testimonials.testimonials.dellaHarris.content;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "places.testimonials.testimonials.dellaHarris.content";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "places.testimonials.testimonials.dellaHarris.content";
                  }
               },
               author():string {
                  try {
                     const translation = _activeBundle.places.testimonials.testimonials.dellaHarris.author;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "places.testimonials.testimonials.dellaHarris.author";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "places.testimonials.testimonials.dellaHarris.author";
                  }
               },
               website():string {
                  try {
                     const translation = _activeBundle.places.testimonials.testimonials.dellaHarris.website;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "places.testimonials.testimonials.dellaHarris.website";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "places.testimonials.testimonials.dellaHarris.website";
                  }
               }
            } as Testimonial;
            export const joelHarris = {
               content():string {
                  try {
                     const translation = _activeBundle.places.testimonials.testimonials.joelHarris.content;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "places.testimonials.testimonials.joelHarris.content";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "places.testimonials.testimonials.joelHarris.content";
                  }
               },
               author():string {
                  try {
                     const translation = _activeBundle.places.testimonials.testimonials.joelHarris.author;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "places.testimonials.testimonials.joelHarris.author";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "places.testimonials.testimonials.joelHarris.author";
                  }
               }
            } as Testimonial;
            export const peterAndTracyWalne = {
               content():string {
                  try {
                     const translation = _activeBundle.places.testimonials.testimonials.peterAndTracyWalne.content;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "places.testimonials.testimonials.peterAndTracyWalne.content";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "places.testimonials.testimonials.peterAndTracyWalne.content";
                  }
               },
               author():string {
                  try {
                     const translation = _activeBundle.places.testimonials.testimonials.peterAndTracyWalne.author;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "places.testimonials.testimonials.peterAndTracyWalne.author";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "places.testimonials.testimonials.peterAndTracyWalne.author";
                  }
               }
            } as Testimonial;
            export const aliMacDonald = {
               content():string {
                  try {
                     const translation = _activeBundle.places.testimonials.testimonials.aliMacDonald.content;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "places.testimonials.testimonials.aliMacDonald.content";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "places.testimonials.testimonials.aliMacDonald.content";
                  }
               },
               author():string {
                  try {
                     const translation = _activeBundle.places.testimonials.testimonials.aliMacDonald.author;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "places.testimonials.testimonials.aliMacDonald.author";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "places.testimonials.testimonials.aliMacDonald.author";
                  }
               }
            } as Testimonial;
            export const philPitt = {
               content():string {
                  try {
                     const translation = _activeBundle.places.testimonials.testimonials.philPitt.content;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "places.testimonials.testimonials.philPitt.content";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "places.testimonials.testimonials.philPitt.content";
                  }
               },
               author():string {
                  try {
                     const translation = _activeBundle.places.testimonials.testimonials.philPitt.author;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "places.testimonials.testimonials.philPitt.author";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "places.testimonials.testimonials.philPitt.author";
                  }
               }
            } as Testimonial;
            export const mitchGroves = {
               content():string {
                  try {
                     const translation = _activeBundle.places.testimonials.testimonials.mitchGroves.content;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "places.testimonials.testimonials.mitchGroves.content";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "places.testimonials.testimonials.mitchGroves.content";
                  }
               },
               author():string {
                  try {
                     const translation = _activeBundle.places.testimonials.testimonials.mitchGroves.author;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "places.testimonials.testimonials.mitchGroves.author";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "places.testimonials.testimonials.mitchGroves.author";
                  }
               }
            } as Testimonial;
            export const mikeSoltys = {
               content():string {
                  try {
                     const translation = _activeBundle.places.testimonials.testimonials.mikeSoltys.content;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "places.testimonials.testimonials.mikeSoltys.content";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "places.testimonials.testimonials.mikeSoltys.content";
                  }
               },
               author():string {
                  try {
                     const translation = _activeBundle.places.testimonials.testimonials.mikeSoltys.author;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "places.testimonials.testimonials.mikeSoltys.author";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "places.testimonials.testimonials.mikeSoltys.author";
                  }
               }
            } as Testimonial;
            export const laurieBell = {
               content():string {
                  try {
                     const translation = _activeBundle.places.testimonials.testimonials.laurieBell.content;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "places.testimonials.testimonials.laurieBell.content";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "places.testimonials.testimonials.laurieBell.content";
                  }
               },
               author():string {
                  try {
                     const translation = _activeBundle.places.testimonials.testimonials.laurieBell.author;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "places.testimonials.testimonials.laurieBell.author";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "places.testimonials.testimonials.laurieBell.author";
                  }
               }
            } as Testimonial;
            export const melanieDesousa = {
               content():string {
                  try {
                     const translation = _activeBundle.places.testimonials.testimonials.melanieDesousa.content;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "places.testimonials.testimonials.melanieDesousa.content";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "places.testimonials.testimonials.melanieDesousa.content";
                  }
               },
               author():string {
                  try {
                     const translation = _activeBundle.places.testimonials.testimonials.melanieDesousa.author;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "places.testimonials.testimonials.melanieDesousa.author";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "places.testimonials.testimonials.melanieDesousa.author";
                  }
               }
            } as Testimonial;
            export const justineWratten = {
               content():string {
                  try {
                     const translation = _activeBundle.places.testimonials.testimonials.justineWratten.content;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "places.testimonials.testimonials.justineWratten.content";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "places.testimonials.testimonials.justineWratten.content";
                  }
               },
               author():string {
                  try {
                     const translation = _activeBundle.places.testimonials.testimonials.justineWratten.author;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "places.testimonials.testimonials.justineWratten.author";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "places.testimonials.testimonials.justineWratten.author";
                  }
               }
            } as Testimonial;
            export const chantelKoetsier = {
               content():string {
                  try {
                     const translation = _activeBundle.places.testimonials.testimonials.chantelKoetsier.content;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "places.testimonials.testimonials.chantelKoetsier.content";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "places.testimonials.testimonials.chantelKoetsier.content";
                  }
               },
               author():string {
                  try {
                     const translation = _activeBundle.places.testimonials.testimonials.chantelKoetsier.author;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "places.testimonials.testimonials.chantelKoetsier.author";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "places.testimonials.testimonials.chantelKoetsier.author";
                  }
               }
            } as Testimonial;
            export const eliseHeuston = {
               content():string {
                  try {
                     const translation = _activeBundle.places.testimonials.testimonials.eliseHeuston.content;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "places.testimonials.testimonials.eliseHeuston.content";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "places.testimonials.testimonials.eliseHeuston.content";
                  }
               },
               author():string {
                  try {
                     const translation = _activeBundle.places.testimonials.testimonials.eliseHeuston.author;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "places.testimonials.testimonials.eliseHeuston.author";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "places.testimonials.testimonials.eliseHeuston.author";
                  }
               }
            } as Testimonial;
            export const tayahWeeks = {
               content():string {
                  try {
                     const translation = _activeBundle.places.testimonials.testimonials.tayahWeeks.content;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "places.testimonials.testimonials.tayahWeeks.content";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "places.testimonials.testimonials.tayahWeeks.content";
                  }
               },
               author():string {
                  try {
                     const translation = _activeBundle.places.testimonials.testimonials.tayahWeeks.author;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "places.testimonials.testimonials.tayahWeeks.author";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "places.testimonials.testimonials.tayahWeeks.author";
                  }
               }
            } as Testimonial;
            export const stevenTopalovic = {
               content():string {
                  try {
                     const translation = _activeBundle.places.testimonials.testimonials.stevenTopalovic.content;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "places.testimonials.testimonials.stevenTopalovic.content";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "places.testimonials.testimonials.stevenTopalovic.content";
                  }
               },
               author():string {
                  try {
                     const translation = _activeBundle.places.testimonials.testimonials.stevenTopalovic.author;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "places.testimonials.testimonials.stevenTopalovic.author";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "places.testimonials.testimonials.stevenTopalovic.author";
                  }
               }
            } as Testimonial;
            export const ebony = {
               content():string {
                  try {
                     const translation = _activeBundle.places.testimonials.testimonials.ebony.content;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "places.testimonials.testimonials.ebony.content";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "places.testimonials.testimonials.ebony.content";
                  }
               },
               author():string {
                  try {
                     const translation = _activeBundle.places.testimonials.testimonials.ebony.author;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "places.testimonials.testimonials.ebony.author";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "places.testimonials.testimonials.ebony.author";
                  }
               }
            } as Testimonial;
         }
      }
   }
}