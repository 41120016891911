import {Component, Input} from '@angular/core';
import {AbstractInputComponent} from '../abstract-input-component';

@Component({
  selector: 'spa-common-textarea',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
})
export class TextAreaComponent extends AbstractInputComponent {
  @Input()
  rows: number;
}
