<spa-common-page-slice>
  <section spa-common-three-columns>
    <div>
      <ng-container *spaLibMarkdown>
        ### Get In Touch
      </ng-container>
      <p spa-common-contact-details></p>
    </div>

    <section *spaLibMarkdown>
      ### Croydon Studio

      My studio is in **Croydon** is within a short **20 minute commute** from suburbs of Croydon Hills,
      Chirnside Park, Ringwood, Lilydale, Mount Evelyn, Mooroolbark, Montrose, Boronia, Kilsyth,
      Heathmont, Warranwood and Wonga Park.
    </section>

    <section>
      <ng-container *spaLibMarkdown>
        ### Map
      </ng-container>
      <iframe loading="lazy" allowfullscreen
              src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJLQ8vlnMw1moRYtw-f_KPqVk&key=AIzaSyDK-3x8teSJHL0ABjnzAvEVZZUgt3vnXuo"></iframe>
    </section>
  </section>
</spa-common-page-slice>
