import {Component, Inject} from '@angular/core';
import {DisposableComponent} from '@coolon/angular-ui-powerups';
import {Dsl} from '@coolon/pectin';
import {CONTACT_DETAILS, ContactDetails} from "./contact-details";

@Component({
  selector: 'spa-common-contact-details, [spa-common-contact-details]',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss'],
})
export class ContactDetailsComponent extends DisposableComponent {

  // readonly i18n = I18n.components.contactDetails;

  constructor(@Inject(CONTACT_DETAILS) readonly contactDetails: ContactDetails) {
    super();
    const {chain} = Dsl.disposeWith(this);
  }
}
