// DO NOT EDIT!!!
// This file is automatically generated using:
//   ng generate @coolon/angular-ui-powerups:place-routes --project=powerup-demo --dir=app/places

import { Visit } from '@coolon/angular-ui-powerups';
import {AboutComponentVisit} from "./place-visits";
import {IvaTechniqueComponentVisit} from "./place-visits";
import {RachelComponentVisit} from "./place-visits";
import {ContactComponentVisit} from "./place-visits";
import {HomeComponentVisit} from "./place-visits";
import {SingingLessonsComponentVisit} from "./place-visits";
import {StudentConcertsComponentVisit} from "./place-visits";
import {TestimonialsComponentVisit} from "./place-visits";


export const placeDestinations = {
  
  about: {
    pathSegment: "about",
    createVisit(): Visit {
      return new AboutComponentVisit();
    }
  },
  
  ivaTechnique: {
    pathSegment: "iva-technique",
    createVisit(): Visit {
      return new IvaTechniqueComponentVisit();
    }
  },
  
  rachel: {
    pathSegment: "rachel",
    createVisit(): Visit {
      return new RachelComponentVisit();
    }
  },
  
  contact: {
    pathSegment: "contact",
    createVisit(): Visit {
      return new ContactComponentVisit();
    }
  },
  
  home: {
    pathSegment: "home",
    createVisit(): Visit {
      return new HomeComponentVisit();
    }
  },
  
  singingLessons: {
    pathSegment: "singing-lessons",
    createVisit(): Visit {
      return new SingingLessonsComponentVisit();
    }
  },
  
  studentConcerts: {
    pathSegment: "student-concerts",
    createVisit(): Visit {
      return new StudentConcertsComponentVisit();
    }
  },
  
  testimonials: {
    pathSegment: "testimonials",
    createVisit(): Visit {
      return new TestimonialsComponentVisit();
    }
  },
  
};
