import {Component, HostBinding, Input} from '@angular/core';
import {DisposableComponent} from "@coolon/angular-ui-powerups";
import {StoreInValueModel, TrackExternal, ValueModel} from "@coolon/pectin";

@Component({
  selector: 'a[spaCommonPhoneNumber]',
  template: '<mat-icon *ngIf="showIcon">call</mat-icon><span>{{phoneNumber$.value}}</span>',
  styleUrls: ['./phone-number.component.scss'],
})
export class PhoneNumberComponent extends DisposableComponent {

  @Input()
  @StoreInValueModel("phoneNumber$")
  spaCommonPhoneNumber: string;

  @Input()
  @TrackExternal()
  phoneNumber$: ValueModel<string>;

  @Input()
  showIcon = false;

  constructor() {
    super();
  }

  @HostBinding("attr.href")
  get href() {
    return `tel:${this.phoneNumber$.value}`;
  }
}
