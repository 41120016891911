import {Clipboard} from '@angular/cdk/clipboard';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostBinding,
  inject,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {DisposableComponent, PlaceRouter} from '@coolon/angular-ui-powerups';
import {
  BooleanValueModelChain,
  Command,
  DirtyTrackingForm,
  DirtyTrackingValueModel,
  Dsl,
  GarbageCollector,
  ListModelChain,
  MutableValueModel,
  StoreInValueModel,
  Trace,
  TrackExternal, TransactionObserver,
  Validator,
  ValueModel, ValueModelChain
} from '@coolon/pectin';
import {I18n, SeoService} from "@spa/common";
import {join} from "lodash-es";
import {InvisibleReCaptchaComponent} from "ngx-captcha";
import * as voca from 'voca';
import {CONTACT_FORM_CONFIGURATION, ContactFormConfiguration} from "./contact-form-configuration";

class FormDataFields {
  name: string;
  email: string;
  phone: string;
  message: string;
  honeyPot: string;
}

@Component({
  selector: 'spa-common-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent extends DisposableComponent implements OnInit, AfterViewInit {

  readonly i18n = I18n.components.contactForm;

  @Input()
  @TrackExternal()
  message$: MutableValueModel<string>;

  @Input()
  @StoreInValueModel("message$")
  message: string;

  @Input()
  @TrackExternal()
  cancelCommand: Command<unknown>;

  @Input()
  @TrackExternal()
  formContext$: ValueModel<string>;

  @Input()
  @StoreInValueModel('formContext$')
  formContext: string;

  @Input()
  @HostBinding("class.dynamic-height")
  dynamicHeight: boolean = true;

  @Output()
  success = new EventEmitter<void>();

  @Output()
  error = new EventEmitter<any>();

  @ViewChild('captchaElem', {static: false})
  reCaptchaComponent: InvisibleReCaptchaComponent;

  readonly name$: DirtyTrackingValueModel<string>;
  readonly email$: DirtyTrackingValueModel<string>;
  readonly phone$: DirtyTrackingValueModel<string>;
  readonly _message$: DirtyTrackingValueModel<string>;
  readonly submitCommand: Command<void>;
  private form: DirtyTrackingForm<FormDataFields>;
  readonly error$ = new MutableValueModel<string>();
  readonly finished$ = new MutableValueModel<boolean>(false);
  readonly honeyPot$: DirtyTrackingValueModel<string>;
  readonly hasMessage$: BooleanValueModelChain;
  readonly messageLines$: ValueModelChain<number>;
  readonly copyToClipboardCommand: Command<void>;

  private readonly clipboard = inject(Clipboard);
  private readonly notifications = inject(MatSnackBar);

  constructor(@Inject(CONTACT_FORM_CONFIGURATION) readonly configuration: ContactFormConfiguration,
              private placeRouter: PlaceRouter,
              private readonly seoService: SeoService) {
    super();
    const {chain} = Dsl.disposeWith(this);

    chain(placeRouter.activeVisits$).pluck("placeDefinition");

    const formIdentifier = chain(seoService.pagePath$)
      .append(this.formContext$)
      .map((v) => voca.snakeCase(v))
      .reduce((values) => join(values, '/'));

    const form = (this.form = new DirtyTrackingForm<FormDataFields>());

    this.name$ = form
      .pluck('name')
      .validateUsing(Validator.present('We\'ll need your name'));

    this.email$ = form
      .pluck('email')
      .validateUsing(Validator.present('We\'ll need your email address'));

    this.phone$ = form.pluck('phone');
    // .validateUsing(Validator.present("We'll need an your name"));

    this._message$ = form
      .wrapValueModel(this.message$)
      .validateUsing(Validator.present('We\'ll need your message'));

    this.honeyPot$ = form.pluck('honeyPot')

    const self = this;
    this.submitCommand = Command.create(async () => {
      if (form.validate()) {
        if (configuration.enableFormSubmission) {
          // https://docs.getform.io/features/spam-filtering/recaptcha-v3/
          // const recaptcha = await this.getRecaptchaTokenFor(formIdentifier.value);
          // console.log({recaptchaToken: recaptcha});
          const formData = new FormData();
          formData.append('name', this.name$.value);
          formData.append('email', this.email$.value);
          formData.append('phone', this.phone$.value);
          formData.append('message', this.message$.value);
          formData.append('form-id', formIdentifier.value);
          // formData.append('g-recaptcha-response', recaptcha);

          if (this.configuration.honeyPot && this.honeyPot$.value) {
            formData.append(this.configuration.honeyPot, this.honeyPot$.value);
          }

          try {
            await this.submitFormData(formData);
            this.success.emit();
          } catch (error: any) {
            self.notifyError(error);
          } finally {
            this.finished$.value = true;
          }
        } else {
          // fake a delay for development
          this.notifyError("Eeek")
          // await new Promise(r => setTimeout(r, 750));
          // this.success.emit();
          this.finished$.value = true;
        }
      }
    });

    form.disableWhile(this.submitCommand.busy$);

    this.cancelCommand.addInterceptor(() => {
      this.form.clearErrors();
      this.finished$.value = false;
      return true;
    });

    this.hasMessage$ = chain.stringValue(this.message$).isNotBlank();
    this.messageLines$ = chain(this.message$).map(content => {
      return content.split(/\r?\n/).length
    }).map(count => Math.min(count, 3));

    this.copyToClipboardCommand = Command.create(() => {
      this.clipboard.copy(this.message$.value);
      this.notifications.open("Message copied!", null,  {
        duration: 1000
      })
    });

  }

  ngOnInit(): void {
    this.form.clearErrors();
  }

  @Trace()
  private async submitFormData(formData: FormData) {
    const response = await fetch(this.configuration.submissionEndpoint, {
      method: 'POST',
      body: formData
    });
    if (response.ok) {
      return response;
    } else {
      throw response;
    }
  }

  @Trace()
  private async getRecaptchaTokenFor(formIdentifier: string): Promise<string> {
    // TEMP hack for V2 until usebasin provides support for V3
    const garbage = new GarbageCollector();
    return new Promise<string>((resolve, error) => {
      const {observe} = Dsl.disposeWith(garbage);
      observe.valuesFrom(this.reCaptchaComponent.success).subscribe(resolve)
      observe.valuesFrom(this.reCaptchaComponent.error).subscribe(error);
      this.reCaptchaComponent.execute()
    }).finally(() => garbage.dispose());

    // When v# is supported...
    // this.recaptchaComponent.resolved.toPromise()
    // const token = await this.recaptchaV3Service
    //   .execute(formIdentifier)
    //   .toPromise();
    // console.log({getRecaptchaTokenFor: token})
    // return token;
  }

  protected notifyError(error: any) {
    console.error(error)
    this.error$.value = error;
    this.error.emit(error);
  }

  get enableCancel() {
    return this.cancelCommand.enabled.value;
  }

  get busy() {
    return this.submitCommand.busy.value;
  }

  @HostBinding("class.finished")
  get finished() {
    return this.finished$.value;
  }

  ngAfterViewInit() {
    this.initFocus();
  }

  initFocus() {
    this.name$.focusContext.requestFocus();
  }
}
