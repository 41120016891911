import {Component, Input} from '@angular/core';
import {PlaceComponent, PlaceDefinition, PlaceRouter, Visit} from "@coolon/angular-ui-powerups";
import {Constructable, Dsl, ListModel, ListModelChain, StoreInListModel, TrackExternal} from '@coolon/pectin';
import {collapseAnimation} from 'angular-animations';
import {NavContainer} from '../nav-container.component';

// @ts-ignore
// const fullConfig = resolveConfig(tailwindConfig);

@Component({
  selector: 'spa-common-nav-bar,[spa-common-nav-bar]',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  animations: [collapseAnimation({anchor: 'hide', duration: 100})],
})
export class NavBarComponent extends NavContainer {

  constructor(placeRouter: PlaceRouter) {
    super(placeRouter);
  }

  toggle($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    $event.stopImmediatePropagation();
    // this.open = !this.open;
  }

  isActive(placeDef:PlaceDefinition):boolean {
    return !!this.placeRouter.activeVisits$.value.find(v => v.placeDefinition === placeDef);
  }

  href(placeDef: PlaceDefinition, child: PlaceDefinition) {
    return `/${placeDef.path}/${child.path}`
  }
}
