import {Component, HostBinding, Input, OnInit} from '@angular/core';

@Component({
  selector: '[spa-common-three-columns]',
  templateUrl: './three-cols.component.html',
  styleUrls: ['./three-cols.component.scss'],
})
export class ThreeColsComponent implements OnInit {

  @Input()
  @HostBinding("attr.grid-lines")
  gridLines: 'left' | "right" | "both" | "none" = "none"

  constructor() {}

  ngOnInit(): void {}
}
