import {Component, Input, OnDestroy} from '@angular/core';
import {DisposableComponent, PlaceComponent, PlaceDefinition, PlaceRouter} from "@coolon/angular-ui-powerups";
import {
  Constructable, Dsl,
  GarbageCollector,
  ListModel,
  ListModelChain,
  StoreInListModel,
  TrackExternal
} from '@coolon/pectin';
import {Subscription} from 'rxjs';
import {AbstractNavComponent} from "./abstract-nav.component";

@Component({
  selector: 'spa-common-nav-container',
  template: '',
  styleUrls: [],
})
export abstract class NavContainer extends AbstractNavComponent {

  private readonly subscriptions: Subscription[] = [];

  @Input()
  @TrackExternal()
  places$: ListModel<Constructable<PlaceComponent>>;

  @Input()
  @StoreInListModel("places$")
  places: Constructable<PlaceComponent>[];

  readonly placeDefinitions$: ListModelChain<PlaceDefinition>;

  constructor(placeRouter:PlaceRouter) {
    super(placeRouter);
    const {chain} = Dsl.disposeWith(this);
    this.placeDefinitions$ = chain(this.places$).map(p => PlaceComponent.getDefinition(p));
  }

  protected registerSubscription(s: Subscription) {
    this.subscriptions.push(s);
    this.onDispose(() => {
      this.subscriptions.forEach((s) => s.unsubscribe());
    })
  }

}
