// DO NOT EDIT!!!
// This file is automatically generated using:
//   ng generate @coolon/angular-ui-powerups:place-routes --project=powerup-demo --dir=app/places

import { Visit, PlaceComponent } from '@coolon/angular-ui-powerups';
import {AboutComponent} from "./about/about.component";
import {IvaTechniqueComponent} from "./about/iva-technique/iva-technique.component";
import {RachelComponent} from "./about/rachel/rachel.component";
import {ContactComponent} from "./contact/contact.component";
import {HomeComponent} from "./home/home.component";
import {SingingLessonsComponent} from "./singing-lessons/singing-lessons.component";
import {StudentConcertsComponent} from "./student-concerts/student-concerts.component";
import {TestimonialsComponent} from "./testimonials/testimonials.component";



export class AboutComponentVisit extends Visit {
  constructor() {
      super(PlaceComponent.getDefinition(AboutComponent), {});
    }
}

export class IvaTechniqueComponentVisit extends Visit {
  constructor() {
      super(PlaceComponent.getDefinition(IvaTechniqueComponent), {});
    }
}

export class RachelComponentVisit extends Visit {
  constructor() {
      super(PlaceComponent.getDefinition(RachelComponent), {});
    }
}

export class ContactComponentVisit extends Visit {
  constructor() {
      super(PlaceComponent.getDefinition(ContactComponent), {});
    }
}

export class HomeComponentVisit extends Visit {
  constructor() {
      super(PlaceComponent.getDefinition(HomeComponent), {});
    }
}

export class SingingLessonsComponentVisit extends Visit {
  constructor() {
      super(PlaceComponent.getDefinition(SingingLessonsComponent), {});
    }
}

export class StudentConcertsComponentVisit extends Visit {
  constructor() {
      super(PlaceComponent.getDefinition(StudentConcertsComponent), {});
    }
}

export class TestimonialsComponentVisit extends Visit {
  constructor() {
      super(PlaceComponent.getDefinition(TestimonialsComponent), {});
    }
}

