import {AfterContentInit, AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import { Dsl, Command } from '@coolon/pectin';
import {Activity, ACTIVITY_DATA} from "@coolon/angular-ui-powerups";
import { I18n } from '../../i18n';
import {ContactFormComponent} from "../contact-form/contact-form.component";

@Component({
  selector: 'spa-common-quick-contact-form-activity',
  templateUrl: './quick-contact-form-activity.component.html',
  styleUrls: ['./quick-contact-form-activity.component.scss'],
})
export class QuickContactFormActivityComponent extends Activity<QuickContactFormActivityComponent.Data, void> implements AfterViewInit {

  readonly i18n = I18n.components.quickContactFormActivity;

  @ViewChild(ContactFormComponent)
  contactForm: ContactFormComponent;

  readonly formContext: string;

  constructor() {
    super();
    const {chain} = Dsl.disposeWith(this);

    this.formContext = this.activityData?.formContext ?? "quick-contact"

    this.confirmCommand = Command.create(() => {
       this.done();
    });
    this.cancelCommand = Command.create(() => {
       this.cancel();
    });

    this.busyWhile(this.confirmCommand.busy);
  }
}

export namespace QuickContactFormActivityComponent {
  export interface Data {
    formContext?: string
    initialBodyText?:string
  }
}
