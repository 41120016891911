// IMPORTANT: This is a GENERATED FILE. DO NOT EDIT
//
// You can regenerate this file using the command:
//   nx workspace-schematic image-asset-scanner --project rachel-pietsch-studios --moduleDir services/images --assetDir assets/images
//
import { ImageRef } from '@spa/common';

export const Images = {
  HANNAH_WALNE_HEADSHOT_1: new ImageRef(
    '/assets/images/Hannah_Walne_Headshot-1.webp',
    300,
    300
  ),

  HANNAH_WALNE_HEADSHOT: new ImageRef(
    '/assets/images/Hannah_Walne_Headshot.webp',
    300,
    300
  ),

  MICROPHONE: new ImageRef('/assets/images/MicroPhone.png', 672, 1070),

  RACHEL_PIETSCH_HEADSHOT_1: new ImageRef(
    '/assets/images/Rachel_Pietsch_Headshot-1.webp',
    300,
    400
  ),

  RACHEL_PIETSCH_HEADSHOT: new ImageRef(
    '/assets/images/Rachel_Pietsch_Headshot.webp',
    300,
    400
  ),

  CONCERTS: {
    '043A8166': new ImageRef('/assets/images/concerts/043A8166.png', 600, 247),

    '0P7A3423': new ImageRef('/assets/images/concerts/0P7A3423.png', 600, 600),

    '163_0P7A6110': new ImageRef(
      '/assets/images/concerts/163-0P7A6110.png',
      600,
      464
    ),

    '237_043A9063_B': new ImageRef(
      '/assets/images/concerts/237-043A9063-b.png',
      600,
      400
    ),

    '243_043A9065_B': new ImageRef(
      '/assets/images/concerts/243-043A9065-b.png',
      600,
      338
    ),

    '297_0P7A6356': new ImageRef(
      '/assets/images/concerts/297-0P7A6356.png',
      600,
      400
    ),

    '314_0P7A6389': new ImageRef(
      '/assets/images/concerts/314-0P7A6389.png',
      600,
      400
    ),

    '34_0P7A5832_1': new ImageRef(
      '/assets/images/concerts/34-0P7A5832_1.png',
      600,
      400
    ),

    '405_0P7A6565': new ImageRef(
      '/assets/images/concerts/405-0P7A6565.png',
      600,
      899
    ),

    '459_0P7A6675': new ImageRef(
      '/assets/images/concerts/459-0P7A6675.png',
      600,
      899
    ),

    '500_043A9125': new ImageRef(
      '/assets/images/concerts/500-043A9125.png',
      600,
      338
    ),

    '56457705_2548385051847381_3169071357707157504_O': new ImageRef(
      '/assets/images/concerts/56457705_2548385051847381_3169071357707157504_o.png',
      600,
      899
    ),

    '56470359_2548385348514018_567314061100843008_O_1': new ImageRef(
      '/assets/images/concerts/56470359_2548385348514018_567314061100843008_o_1.png',
      600,
      400
    ),

    '56474580_2548385728513980_658429597557194752_O': new ImageRef(
      '/assets/images/concerts/56474580_2548385728513980_658429597557194752_o.png',
      600,
      400
    ),

    '56563186_2548385281847358_4196761292523962368_O': new ImageRef(
      '/assets/images/concerts/56563186_2548385281847358_4196761292523962368_o.png',
      600,
      899
    ),

    '56589748_2548385028514050_7925084310393061376_O': new ImageRef(
      '/assets/images/concerts/56589748_2548385028514050_7925084310393061376_o.png',
      600,
      464
    ),

    '56593617_2548384941847392_1786151502541225984_O': new ImageRef(
      '/assets/images/concerts/56593617_2548384941847392_1786151502541225984_o.png',
      600,
      338
    ),

    '56673224_2548385615180658_2537403383543758848_O': new ImageRef(
      '/assets/images/concerts/56673224_2548385615180658_2537403383543758848_o.png',
      600,
      400
    ),

    '56770516_2548384935180726_5723464373448474624_O': new ImageRef(
      '/assets/images/concerts/56770516_2548384935180726_5723464373448474624_o.png',
      600,
      899
    ),

    '56795621_2548385128514040_7779563912097169408_O': new ImageRef(
      '/assets/images/concerts/56795621_2548385128514040_7779563912097169408_o.png',
      600,
      400
    ),

    '57267688_2548385751847311_6380634050939322368_O': new ImageRef(
      '/assets/images/concerts/57267688_2548385751847311_6380634050939322368_o.png',
      600,
      899
    ),

    '65_0P7A5898': new ImageRef(
      '/assets/images/concerts/65-0P7A5898.png',
      600,
      749
    ),

    '85_0P7A5936': new ImageRef(
      '/assets/images/concerts/85-0P7A5936.png',
      600,
      400
    ),
  },

  IVA_BADGE: new ImageRef('/assets/images/iva-badge.jpg', 200, 254),

  IVA_LOGO: new ImageRef('/assets/images/iva-logo.png', 402, 235),

  LOGO: new ImageRef('/assets/images/logo.png', 3252, 522),

  ONLINE_LESSONS_1: new ImageRef(
    '/assets/images/online-lessons-1.webp',
    300,
    226
  ),

  ONLINE_LESSONS: new ImageRef('/assets/images/online-lessons.webp', 300, 226),
};
