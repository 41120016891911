<rps-page-title title="Contact">
</rps-page-title>
<spa-common-page-slice sliceContentClass="main-layout">
  
  <section spa-common-two-columns [gridlines]="true">
    <section class="contact-form">
      <h3>Leave a message</h3>
      <spa-common-contact-form></spa-common-contact-form>
    </section>

    <section class="contact-details">
      <h3>Contact Details</h3>
      <spa-common-contact-details></spa-common-contact-details>
    </section>
    
  </section>

</spa-common-page-slice>

