import {Component, Input} from '@angular/core';
import {DisposableComponent, PlaceComponent} from '@coolon/angular-ui-powerups';
import {Constructable, Dsl, ListModel, MutableValueModel, StoreInListModel, Trace, TrackExternal} from '@coolon/pectin';
import {I18n} from '../../i18n';

@Component({
  selector: 'rps-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss'],
})
export class PageFooterComponent extends DisposableComponent {

  readonly i18n = I18n.components.pageHeader;

  @Input()
  @TrackExternal()
  places$: ListModel<Constructable<PlaceComponent>>;

  @Input()
  @StoreInListModel("places$")
  places: Constructable<PlaceComponent>[];

  readonly menuOpen$ = new MutableValueModel(false);

  constructor() {
    super();
    const {chain} = Dsl.disposeWith(this);
  }

  @Trace()
  toggleMenu() {
    this.menuOpen$.value = !this.menuOpen$.value;
  }
}
