import {Component, ElementRef, HostBinding, Inject, Input, ViewChild} from '@angular/core';
import {DisposableComponent, InteractionService} from '@coolon/angular-ui-powerups';
import {Command, Dsl, StoreInValueModel, TrackExternal, ValueModel} from '@coolon/pectin';
import {
  CONTACT_FORM_CONFIGURATION,
  ContactFormConfiguration,
  ImageRef,
  QuickContactFormActivityComponent
} from "@spa/common";
import {isEmpty} from 'lodash-es';
import {I18n} from '../../i18n';

@Component({
  selector: 'rps-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss'],
})
export class PageTitleComponent extends DisposableComponent {

  readonly i18n = I18n.components.pageHeader;

  @Input()
  @TrackExternal()
  title$: ValueModel<String>;

  @Input()
  @StoreInValueModel("title$")
  title: String;

  @Input()
  @TrackExternal()
  imageRef$: ValueModel<ImageRef>;

  @Input()
  @StoreInValueModel("imageRef$")
  imageRef: ImageRef;

  @Input()
  showCallToAction = false;

  @ViewChild("contentContainer")
  contentWrapper: ElementRef;

  readonly quickContactCommand: Command<void>;

  constructor(@Inject(CONTACT_FORM_CONFIGURATION) readonly contactConfiguration: ContactFormConfiguration, interactionService: InteractionService) {
    super();
    const {chain} = Dsl.disposeWith(this);

    const interactionContext = interactionService.createModalInteractionContextDisposedWith(this);

    this.quickContactCommand = Command.create(() => {
      interactionContext.startActivity(QuickContactFormActivityComponent);
    })
  }

  @HostBinding("class.empty")
  get empty() {
    let textContent = this.contentWrapper?.nativeElement.textContent;
    return isEmpty(textContent?.trim());
  }


}
