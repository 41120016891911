<spa-common-page-slice padding="none">
  <section>
    <img [src]="Images.MICROPHONE.src" sizes="100vw"
         [width]="Images.MICROPHONE.normalisedWidth"
         [height]="Images.MICROPHONE.normalisedHeight"
         alt=""
         (click)="goHome($event)">
    <a class="title" href="/" (click)="goHome($event)">
      <img [src]="Images.LOGO.src" sizes="100vw"
           [style]="logoStyle"
           alt="Rachel Pietsch Vocal Studios">
    </a>
    <nav spa-common-nav-bar [places]="places"></nav>
  </section>
</spa-common-page-slice>
