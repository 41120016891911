import {Component, HostBinding, Input, OnInit} from '@angular/core';

@Component({
  selector: '[spa-common-two-columns]',
  templateUrl: './two-columns.component.html',
  styleUrls: ['./two-columns.component.scss'],
})
export class TwoColumnsComponent implements OnInit {

  @Input()
  @HostBinding("class.divider")
  gridlines: boolean = false;

  constructor() {
  }

  ngOnInit(): void {
  }
}
