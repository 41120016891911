<form>
  <div class='fields' [class.busy]='busy'>
    <spa-common-input name='name' label='Your Name' [valueModel]='name$'></spa-common-input>
    <spa-common-input name='email' label='Email' [valueModel]='email$'></spa-common-input>

    <spa-common-input
      name='phone'
      label='Phone Numer (Optional)'
      [valueModel]='phone$'
    ></spa-common-input>

    <spa-common-textarea
      name='message'
      label='Your Message'
      [rows]='6'
      [valueModel]='_message$'
    ></spa-common-textarea>
    <div class='tandc'>
      This site is protected by reCAPTCHA and the Google
      <a href='https://policies.google.com/privacy' tabindex='-1'>Privacy Policy</a> and
      <a href='https://policies.google.com/terms' tabindex='-1'>Terms of Service</a> apply.
    </div>
  </div>
<!--  <ngx-invisible-recaptcha #captchaElem-->
<!--                           [siteKey]="configuration.recaptchaSiteKey"-->
<!--                           [useGlobalDomain]="false">-->
<!--  </ngx-invisible-recaptcha>-->
  <input *ngIf="configuration.honeyPot" name="{{configuration.honeyPot}}" type="hidden" [(ngModel)]="honeyPot$.value">
  <div class='actions'>
    <button
      *ngIf='cancelCommand.enabled.value && !busy'
      type='button'
      buttonStyle='outline'
      [pectinCommand]='cancelCommand'
    >Cancel
    </button>
    <button type='button' buttonStyle='raised' [pectinCommand]='submitCommand'>
      <span *ngIf='!submitCommand.busy$.value'>Submit</span>
      <span *ngIf='submitCommand.busy$.value'>Please wait...</span>
    </button>
  </div>
</form>
<div class='overlay' *ngIf='busy'></div>

<div class="postscript">
  <aup-markdown *ngIf="!error$.value" class="success" [content]="configuration.confirmationMessage"></aup-markdown>
  <div *ngIf="error$.value" class="error">
    <aup-markdown>
      #### Hmmm, Something went wrong...

      It looks like something has gone wrong with all the internet things.

      In the meantime you can get in contact via the following:
    </aup-markdown>
    <section class="original-message" *ngIf="hasMessage$.value">
      <label><mat-icon>info</mat-icon>Here's the message you entered (<a [pectinCommand]='copyToClipboardCommand'>Copy to clipboard</a>)</label>
      <textarea [ngModel]="message$.value" [rows]="messageLines$.value"></textarea>
    </section>

    <spa-common-contact-details></spa-common-contact-details>

  </div>
  <div class="actions">
    <button *ngIf='cancelCommand.enabled$.value'
            type='button'
            buttonStyle='outline'
            [pectinCommand]='cancelCommand'
    >Close
    </button>
  </div>
</div> 

