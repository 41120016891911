export class ImageRef {
  readonly normalisedHeight: number;
  readonly normalisedWidth: number;
  readonly aspectRatio: number;

  constructor(readonly src: string, readonly width: number, readonly height: number) {
    const aspectRatio = this.aspectRatio = width / height;
    if (aspectRatio < 1) {
      this.normalisedHeight = 100;
      this.normalisedWidth = 100 * aspectRatio;
    } else {
      this.normalisedWidth = 100;
      this.normalisedHeight = 100 * aspectRatio;
    }
  }

  get aspectRatioAsCss() {
    return `${this.width} / ${this.height}`;
  }
}
