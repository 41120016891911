import {Component, HostBinding, Input, OnInit} from '@angular/core';

@Component({
  selector: 'a[spaCommonEmailAddress]',
  template: '{{emailAddress}}',
  styleUrls: ['./email-address.component.scss'],
})
export class EmailAddressComponent {

  @Input()
  spaCommonEmailAddress: string;

  constructor() {}

  @HostBinding("attr.href")
  get href() {
    return `mailto:${this.emailAddress}`;
  }

  get emailAddress() {
    return this.spaCommonEmailAddress;
  }
}
