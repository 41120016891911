<rps-page-title title="Rachel Pietsch Vocal Studio" [imageRef]="Images.RACHEL_PIETSCH_HEADSHOT"
                [showCallToAction]="true">

  <p class="text-xl">
    Welcome to my Vocal Studio, located in Croydon in the outer eastern suburbs of Melbourne, Australia.
  </p>

  <ng-container *spaLibMarkdown>
    I am a highly experienced performer and educator, specialising in teaching voice function across all different types
    of voices. I am known for my ear for detail and my ability to open up and free voices, including tricky ones.

    If you're experiencing difficulty in any aspect of your voice, or even just have a desire to sing or speak better,
    you're in the right place. I have helped many singers and speakers, ranging from beginners to advanced, find their true voice.
    
    It would be my privilege to help you find yours!
  </ng-container>

</rps-page-title>

<spa-common-page-slice>

  <section spa-common-three-columns gridLines="right">

    <section>
      <ng-container *spaLibMarkdown>
        ### Learn to Sing

        Being able to sing with passion & confidence is exhilarating and deeply fulfilling. I can help you find your
        unique sound and develop the skills needed to build endurance, whether you want to, or already, sing
        professionally or just for enjoyment.

      </ng-container>

      <a class="button mt-6" buttonStyle="raised" [href]="singingLessonsHref">Learn More...</a>
    </section>

    <!--    <section *spaLibMarkdown>-->
    <!--      ### Speech Training-->

    <!--      Playing piano feeds the soul and stimulates the mind. Whether you're young or old it's never too late to start!-->
    <!--    </section>-->

    <section *spaLibMarkdown>
      ### Trial Lesson

      Not sure if I'm the right fit for you? Why not book a trial lesson where you can experience what a lesson
      would be like - you will receive a **full voice assessment** and the opportunity to experience **singing
      exercises
      designed specifically for your voice**, all in line with your needs and aspirations.

      You get to try before committing, with absolutely no obligation to sign up.
    </section>

    <section class="list-check">
      <ng-container *spaLibMarkdown>
        ### Why choose me?

        * Over 25 years teaching experience.
        * Trained by the best singing teachers in the world.
        * Experienced musician and performer.
        * I am a qualified educator with a strong commitment to professional development.
        * I run a studio concert every year!
      </ng-container>

      <div class="mt-6">
        <a class="button" buttonStyle="outline" [href]="aboutRachelHref">Learn more...</a>
      </div>
    </section>


  </section>
</spa-common-page-slice>

<spa-common-page-slice class="bg-gray-50">
  <h2 class="text-center">Feedback</h2>
  <section spa-common-two-columns>
    <spa-common-testimonial [testimonial]="testimonials.dellaHarris"></spa-common-testimonial>
    <spa-common-testimonial [testimonial]="testimonials.peterAndTracyWalne"></spa-common-testimonial>
  </section>
</spa-common-page-slice>
