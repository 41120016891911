import {CommonModule} from "@angular/common";
import {NgModule} from '@angular/core';
import {MatButtonModule} from "@angular/material/button";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatIconModule} from "@angular/material/icon";
import {
    AngularUiPowerupsModule,
    ComponentsModule as AupComponentsModule,
    InteractionModule
} from "@coolon/angular-ui-powerups";
import {ImgixAngularModule} from "@imgix/angular";
import {CallToActionButtonsComponent} from "./call-to-action-buttons/call-to-action-buttons.component";
import {MobilePageHeaderComponent} from "./mobile-page-header/mobile-page-header.component";
import {PageFooterComponent} from "./page-footer/page-footer.component";
import {PageHeaderComponent} from "./page-header/page-header.component";
import {CommonModule as SpaCommonModule} from "@spa/common";
import {PageTitleComponent} from "./page-title/page-title.component";

@NgModule({
    imports: [
        AupComponentsModule,
        InteractionModule,
        SpaCommonModule,
        CommonModule,
        MatButtonToggleModule,
        MatButtonModule,
        MatIconModule,
        AngularUiPowerupsModule,
        ImgixAngularModule
    ],
  declarations: [
    PageHeaderComponent,
    MobilePageHeaderComponent,
    PageTitleComponent,
    PageFooterComponent,
    CallToActionButtonsComponent
  ],
    exports: [
        PageHeaderComponent,
        MobilePageHeaderComponent,
        PageTitleComponent,
        PageFooterComponent,
      CallToActionButtonsComponent

    ],
  providers: []
})
export class ComponentsModule {
  constructor() {
  }
}
