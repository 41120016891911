<rps-page-title title="Singing Lessons" [imageRef]="Images.HANNAH_WALNE_HEADSHOT" [showCallToAction]="true">

  <p class="text-xl">
    Being able to sing with passion & confidence is exhilarating and deeply fulfilling.
  </p>

  <ng-container *spaLibMarkdown>
    I'm an **IVA Trained Instructor (Advanced)** and can help you whether you want to
    sing or improve professionally or just for enjoyment.

    I love to create a **relaxed & friendly environment** for everyone. I also offer new students a
    no obligation trial lesson where you can experience a mini-lesson and ask any questions you may have.
  </ng-container>

</rps-page-title>

<spa-common-page-slice>
  <section spa-common-two-columns [gridlines]="true">
    <section class="list-check-circle" *spaLibMarkdown>
      What will I learn?
      ------------------

      * To sing with ease and power through your entire range with consistency and without strain.
      * Techniques and skills to improve your vocal range.
      * How to go from low to high without straining, cracking or going breathy.
      * Techniques to prepare for performance and to give you endurance.
    </section>

    <section class="list-check-circle" *spaLibMarkdown>
      ## What ages do you teach?

      * I only teach **early teens** and **upwards**.
      * Late primary (ages 10 -12) by audition only.

      #### What about younger children?

      For younger children I encourage more general musical development, such as
      moving in time and singing in tune, before embarking on formal lessons (which
      are more demanding than most younger children like!)

      Programs like Kinderbeat/Encore, Mini Maestros, Hey Dee Ho, Kodaly, or just
      learning an instrument are a great way to get younger children started in music.
    </section>
  </section>
</spa-common-page-slice>

<spa-common-page-slice  class="bg-gray-50">
  <section spa-common-right-sidebar>
    <section>
      <ng-container *spaLibMarkdown>
        ## First time having lessons?

        Don't worry, we've all been there and know how it feels! All lessons are taken in a friendly & encouraging environment.
        I teach every level, from beginners to advanced.

        I take things at your pace, and tailor the lessons to suit your individual needs and abilities.
      </ng-container>
      <p class="mt-8">
        <a class="button" buttonStyle="raised" [href]="testimonialsHref">View testimonials page...</a>
      </p>
    </section>
    <section sidebar>
      <spa-common-testimonial *ngFor="let testimonial of testimonials" [testimonial]="testimonial"></spa-common-testimonial>
    </section>
  </section>
</spa-common-page-slice>

<spa-common-page-slice>
  <section spa-common-right-sidebar>
    <section>
      <section *spaLibMarkdown class="list-check-circle">
        ## What is the IVA?
        The Institute for Vocal Advancement (IVA) is ...
        
        * Designed to build healthy singing habits, prevent vocal issues and overcome any existing problems.
        * Exercises are tailored specifically to suit the individual needs and characteristics of your voice.
        * Suits all singing styles.
        * Based on the latest vocal science.
        * IVA is dedicated to continual growth and learning.
      </section>
      <p class="mt-8">
        <a class="button" buttonStyle="raised" [href]="ivaHref">Learn more about the IVA...</a>
      </p>
    </section>
    <div sidebar>
      <img ixImg [path]="Images.IVA_BADGE.src" [width]="Images.IVA_BADGE.normalisedWidth"
           [height]="Images.IVA_BADGE.normalisedHeight"/>
    </div>
  </section>

</spa-common-page-slice>


