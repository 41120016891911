/******** IMPORTANT *********
 * 
 * This file is automatically generated.  Any edits will be lost.
 * 
 ****/
/* tslint:disable:no-shadowed-variable */

import MessageFormat from 'message-format';
import {isObject, isEmpty, isNumber, isNaN} from 'lodash-es';
import {Alert} from '@coolon/angular-ui-powerups';
import {Confirm} from '@coolon/angular-ui-powerups';
import {NavItem} from '@coolon/angular-ui-powerups';
import {CommandFace} from '@coolon/angular-ui-powerups';
import {ColumnTextProperties} from '@coolon/angular-ui-powerups';
import defaultBundle from './locales/en.json';
import messageKeysBundle from './locales/message-keys.json';

const _messageLocaleKey = "message-keys";
export namespace I18n {
   let _locale:string = null;
   let _activeBundle:any = null;
   const _bundles = new Map<string,any>();
   registerBundle(_messageLocaleKey, messageKeysBundle);
   registerBundle("en", defaultBundle);
   setLocale("en");
   export function registerBundle(locale:string, bundle:any):void {
      if (_bundles.has(locale)) { throw new Error("bundle already registered for: " + locale)}
      _bundles.set(locale, bundle);
   }
   export function setLocale(locale:string):void {
      if (!_bundles.has(locale)) { throw new Error("No bundle registered for: " + locale)}
      _locale = locale;
      _activeBundle = _bundles.get(_locale);
   }
   export function getAvailableLocales():string[] {
      return Array.from(_bundles.keys()).filter(key => key !== _messageLocaleKey);
   }
   export function getLocale():string {
      return _locale
   }
   export namespace components {
      export namespace contactDetails {
         export function name():string {
            try {
               const translation = _activeBundle.components.contactDetails.name;
               if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                  return "missing key " + "components.contactDetails.name";
               } else { 
                  return translation;
               }
            } catch (error) { 
               return "missing key " + "components.contactDetails.name";
            }
         }
      }
      export namespace contactForm {
         export namespace commands {
            export const submit = {
               text():string {
                  try {
                     const translation = _activeBundle.components.contactForm.commands.submit.text;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "components.contactForm.commands.submit.text";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "components.contactForm.commands.submit.text";
                  }
               },
               color():string {
                  try {
                     const translation = _activeBundle.components.contactForm.commands.submit.color;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "components.contactForm.commands.submit.color";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "components.contactForm.commands.submit.color";
                  }
               },
               style():string {
                  try {
                     const translation = _activeBundle.components.contactForm.commands.submit.style;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "components.contactForm.commands.submit.style";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "components.contactForm.commands.submit.style";
                  }
               }
            } as CommandFace;
            export const submitBusy = {
               text():string {
                  try {
                     const translation = _activeBundle.components.contactForm.commands.submitBusy.text;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "components.contactForm.commands.submitBusy.text";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "components.contactForm.commands.submitBusy.text";
                  }
               },
               color():string {
                  try {
                     const translation = _activeBundle.components.contactForm.commands.submitBusy.color;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "components.contactForm.commands.submitBusy.color";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "components.contactForm.commands.submitBusy.color";
                  }
               },
               style():string {
                  try {
                     const translation = _activeBundle.components.contactForm.commands.submitBusy.style;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "components.contactForm.commands.submitBusy.style";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "components.contactForm.commands.submitBusy.style";
                  }
               }
            } as CommandFace;
            export const cancel = {
               text():string {
                  try {
                     const translation = _activeBundle.components.contactForm.commands.cancel.text;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "components.contactForm.commands.cancel.text";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "components.contactForm.commands.cancel.text";
                  }
               }
            } as CommandFace;
         }
      }
      export namespace nav {
         export namespace mobileSideNav {
            export function name():string {
               try {
                  const translation = _activeBundle.components.nav.mobileSideNav.name;
                  if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                     return "missing key " + "components.nav.mobileSideNav.name";
                  } else { 
                     return translation;
                  }
               } catch (error) { 
                  return "missing key " + "components.nav.mobileSideNav.name";
               }
            }
         }
         export namespace navBar {
            export function name():string {
               try {
                  const translation = _activeBundle.components.nav.navBar.name;
                  if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                     return "missing key " + "components.nav.navBar.name";
                  } else { 
                     return translation;
                  }
               } catch (error) { 
                  return "missing key " + "components.nav.navBar.name";
               }
            }
         }
         export namespace navStack {
            export function name():string {
               try {
                  const translation = _activeBundle.components.nav.navStack.name;
                  if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                     return "missing key " + "components.nav.navStack.name";
                  } else { 
                     return translation;
                  }
               } catch (error) { 
                  return "missing key " + "components.nav.navStack.name";
               }
            }
         }
      }
      export namespace pageNotFound {
         export function message():string {
            try {
               const translation = _activeBundle.components.pageNotFound.message;
               if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                  return "missing key " + "components.pageNotFound.message";
               } else { 
                  return translation;
               }
            } catch (error) { 
               return "missing key " + "components.pageNotFound.message";
            }
         }
      }
      export namespace pageSlice {
         export function name():string {
            try {
               const translation = _activeBundle.components.pageSlice.name;
               if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                  return "missing key " + "components.pageSlice.name";
               } else { 
                  return translation;
               }
            } catch (error) { 
               return "missing key " + "components.pageSlice.name";
            }
         }
      }
      export namespace quickContactFormActivity {
         export function title():string {
            try {
               const translation = _activeBundle.components.quickContactFormActivity.title;
               if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                  return "missing key " + "components.quickContactFormActivity.title";
               } else { 
                  return translation;
               }
            } catch (error) { 
               return "missing key " + "components.quickContactFormActivity.title";
            }
         }
         export namespace actions {
            export const confirm = {
               text():string {
                  try {
                     const translation = _activeBundle.components.quickContactFormActivity.actions.confirm.text;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "components.quickContactFormActivity.actions.confirm.text";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "components.quickContactFormActivity.actions.confirm.text";
                  }
               },
               icon():string {
                  try {
                     const translation = _activeBundle.components.quickContactFormActivity.actions.confirm.icon;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "components.quickContactFormActivity.actions.confirm.icon";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "components.quickContactFormActivity.actions.confirm.icon";
                  }
               },
               color():string {
                  try {
                     const translation = _activeBundle.components.quickContactFormActivity.actions.confirm.color;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "components.quickContactFormActivity.actions.confirm.color";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "components.quickContactFormActivity.actions.confirm.color";
                  }
               },
               style():string {
                  try {
                     const translation = _activeBundle.components.quickContactFormActivity.actions.confirm.style;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "components.quickContactFormActivity.actions.confirm.style";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "components.quickContactFormActivity.actions.confirm.style";
                  }
               }
            } as CommandFace;
            export const cancel = {
               text():string {
                  try {
                     const translation = _activeBundle.components.quickContactFormActivity.actions.cancel.text;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "components.quickContactFormActivity.actions.cancel.text";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "components.quickContactFormActivity.actions.cancel.text";
                  }
               },
               icon():string {
                  try {
                     const translation = _activeBundle.components.quickContactFormActivity.actions.cancel.icon;
                     if ((isEmpty(translation) && !isNumber(translation)) || isNaN(translation)) {
                        return "missing key " + "components.quickContactFormActivity.actions.cancel.icon";
                     } else { 
                        return translation;
                     }
                  } catch (error) { 
                     return "missing key " + "components.quickContactFormActivity.actions.cancel.icon";
                  }
               }
            } as CommandFace;
         }
      }
   }
}