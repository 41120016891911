import { Component, Input, OnInit } from '@angular/core';
import {ImageRef} from "../../services/image/image-ref";

@Component({
  selector: 'spa-common-image-frame',
  templateUrl: './image-frame.component.html',
  styleUrls: ['./image-frame.component.scss'],
})
export class ImageFrameComponent implements OnInit {

  @Input()
  imageRef: ImageRef;

  constructor() {}

  ngOnInit(): void {}
}
