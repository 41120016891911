<ng-container *ngFor="let placeDef of placeDefinitions$.value">

  <ng-container *ngIf="placeDef.children.length > 0">
    <a [matMenuTriggerFor]="menu" [spaCommonPlaceLinkActive]="placeDef" class="place-link-parent">
      {{getNavData(placeDef).title()}}
      <svg
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </a>
    <mat-menu #menu class="nav-bar-popup">
      <a mat-menu-item *ngFor="let child of placeDef.children"
         class="place-link"
         [href]="href(placeDef, child)"
         [class.active]="isActive(child)"
         (click)="navigate($event, placeDef, child)">{{getNavData(child).title()}}</a>
    </mat-menu>
  </ng-container>

  <ng-container *ngIf="placeDef.children.length === 0">
    <a spa-common-place-link [placeDefinition]="placeDef"></a>
  </ng-container>

</ng-container>
