import {Component, Input, OnInit} from '@angular/core';
import {
  Dsl,
  Command,
  StoreInValueModel,
  TrackExternal,
  ValueModel,
  MutableValueModel,
  ListModel,
  Constructable, StoreInListModel
} from '@coolon/pectin';
import {
  DisposableComponent,
  PlaceComponent,
  PlaceRouter,
  slideRightOnEnterAnimation
} from '@coolon/angular-ui-powerups';
import { I18n } from '../../../i18n';

@Component({
  selector: 'spa-common-mobile-side-nav',
  templateUrl: './mobile-side-nav.component.html',
  styleUrls: ['./mobile-side-nav.component.scss'],
  animations:[
    slideRightOnEnterAnimation
  ]
})
export class MobileSideNavComponent extends DisposableComponent {

  readonly i18n = I18n.components.nav.mobileSideNav;

  readonly toggleMenuCommand: Command<void>;
  readonly mobileNavOpen$ = new MutableValueModel<boolean>(false);

  @Input()
  @TrackExternal()
  places$: ListModel<Constructable<PlaceComponent>>;

  @Input()
  @StoreInListModel("places$")
  places: Constructable<PlaceComponent>[];

  constructor(placeRouter: PlaceRouter) {
    super();
    const {chain, when} = Dsl.disposeWith(this);
    when(placeRouter.navigationInProgress$).becomesTruthy(() => {
      this.mobileNavOpen$.value = false;
    });

    this.toggleMenuCommand = Command.create(() => {
      this.mobileNavOpen$.value = !this.mobileNavOpen$.value;
    })

  }
}
