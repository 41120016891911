import {Component, Directive, HostListener, Input} from '@angular/core';
import {NavItem, PlaceDefinition, PlaceRouter, Visit} from "@coolon/angular-ui-powerups";
import {
  BooleanValueModelChain,
  Dsl, ListModel, ListModelChain, StoreInListModel,
  StoreInValueModel,
  TrackExternal,
  ValueModel,
  ValueModelChain
} from '@coolon/pectin';
import {Subscription} from 'rxjs';
import {AbstractNavComponent} from "./abstract-nav.component";

// @Component({
//   selector: 'spa-common-nav-container',
//   template: '',
//   styleUrls: [],
// })
@Directive()
export abstract class AbstractNavLinkComponent extends AbstractNavComponent {

  private readonly subscriptions: Subscription[] = [];

  @Input()
  @TrackExternal()
  placeDefinition$: ValueModel<PlaceDefinition>;

  @Input()
  @StoreInValueModel("placeDefinition$")
  placeDefinition: PlaceDefinition;

  @Input()
  @TrackExternal()
  parentPlaceDefinitions$: ListModel<PlaceDefinition>;

  @Input()
  @StoreInListModel("parentPlaceDefinitions$")
  parentPlaceDefinitions: PlaceDefinition[];


  readonly active$: BooleanValueModelChain;
  readonly title$: ValueModelChain<string>;
  readonly placeDefinitions$: ListModelChain<PlaceDefinition>;
  readonly placePath$: ValueModelChain<string>;

  constructor(placeRouter:PlaceRouter) {
    super(placeRouter);
    const {chain} = Dsl.disposeWith(this);
    this.active$ = chain(placeRouter.activeVisits$)
      .pluck("placeDefinition")
      .includes(this.placeDefinition$);
    const navData = chain(this.placeDefinition$).map(d => this.getNavData(d));
    this.title$ = navData.map(d => d.title());
    this.placeDefinitions$ = chain(this.parentPlaceDefinitions$).append(this.placeDefinition$);
    this.placePath$ = chain(this.placeDefinitions$).map(p => p.path).reduce(bits => `/${bits.join("/")}`)
  }

  // @HostListener("click", ["$event"])
  // navigate($event: Event) {
  //   $event.preventDefault();
  //   $event.stopImmediatePropagation();
  //   this.placeRouter.navigate(this.visitPath.value.map(def =>new Visit(def)))
  // }


  protected registerSubscription(s: Subscription) {
    this.subscriptions.push(s);
    this.onDispose(() => {
      this.subscriptions.forEach((s) => s.unsubscribe());
    })
  }

}
