import { Component, Input, OnInit } from '@angular/core';
import {DisposableComponent} from "@coolon/angular-ui-powerups";
import {Dsl, StoreInValueModel, TrackExternal, ValueModel, ValueModelChain} from "@coolon/pectin";
import { isFunction } from 'lodash-es';
import {Testimonial} from "./testimonial";

@Component({
  selector: 'spa-common-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.scss'],
})
export class TestimonialComponent extends DisposableComponent {

  @Input()
  @TrackExternal()
  testimonial$: ValueModel<Testimonial>;

  @Input()
  @StoreInValueModel("testimonial$")
  testimonial: Testimonial;

  readonly website$: ValueModelChain<string>;
  readonly author$: ValueModelChain<string>;


  constructor() {
    super();
    const {chain} = Dsl.disposeWith(this);
    this.website$ = chain(this.testimonial$).map(t => isFunction(t.website) && t.website())
    this.author$ = chain(this.testimonial$).map(t => isFunction(t.author) && t.author())
  }

  ngOnInit(): void {}
}
