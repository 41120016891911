// DO NOT EDIT!!!
// This file is automatically generated using:
//   ng generate @coolon/angular-ui-powerups:place-routes --project=powerup-demo --dir=app/places

import { Routes } from '@angular/router';
import { PlaceGuard } from '@coolon/angular-ui-powerups';

import {AboutComponent} from "./about/about.component";
import {IvaTechniqueComponent} from "./about/iva-technique/iva-technique.component";
import {RachelComponent} from "./about/rachel/rachel.component";
import {ContactComponent} from "./contact/contact.component";
import {HomeComponent} from "./home/home.component";
import {SingingLessonsComponent} from "./singing-lessons/singing-lessons.component";
import {StudentConcertsComponent} from "./student-concerts/student-concerts.component";
import {TestimonialsComponent} from "./testimonials/testimonials.component";


export const placeRoutes:Routes = [
    {
    path: "about",
    component: AboutComponent,
    canActivate: [PlaceGuard],
    canDeactivate: [PlaceGuard],
    runGuardsAndResolvers: "paramsOrQueryParamsChange",
    children:[
      {path:"", redirectTo:"rachel", pathMatch:"full"}, {
    path: "rachel",
    component: RachelComponent,
    canActivate: [PlaceGuard],
    canDeactivate: [PlaceGuard],
    runGuardsAndResolvers: "paramsOrQueryParamsChange",
    children:[
      
    ]
  }, {
    path: "iva-technique",
    component: IvaTechniqueComponent,
    canActivate: [PlaceGuard],
    canDeactivate: [PlaceGuard],
    runGuardsAndResolvers: "paramsOrQueryParamsChange",
    children:[
      
    ]
  }
    ]
  },

    {
    path: "contact",
    component: ContactComponent,
    canActivate: [PlaceGuard],
    canDeactivate: [PlaceGuard],
    runGuardsAndResolvers: "paramsOrQueryParamsChange",
    children:[
      
    ]
  },

    {
    path: "home",
    component: HomeComponent,
    canActivate: [PlaceGuard],
    canDeactivate: [PlaceGuard],
    runGuardsAndResolvers: "paramsOrQueryParamsChange",
    children:[
      
    ]
  },

    {
    path: "singing-lessons",
    component: SingingLessonsComponent,
    canActivate: [PlaceGuard],
    canDeactivate: [PlaceGuard],
    runGuardsAndResolvers: "paramsOrQueryParamsChange",
    children:[
      
    ]
  },

    {
    path: "student-concerts",
    component: StudentConcertsComponent,
    canActivate: [PlaceGuard],
    canDeactivate: [PlaceGuard],
    runGuardsAndResolvers: "paramsOrQueryParamsChange",
    children:[
      
    ]
  },

    {
    path: "testimonials",
    component: TestimonialsComponent,
    canActivate: [PlaceGuard],
    canDeactivate: [PlaceGuard],
    runGuardsAndResolvers: "paramsOrQueryParamsChange",
    children:[
      
    ]
  },

];
