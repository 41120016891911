<label for="{{ name }}"
  >{{ label
  }}<span class="error" *ngIf="error.value"> - {{ error.value }}</span></label
>
<textarea
  id="{{ name }}"
  type="text"
  name="{{ name }}"
  [rows]="rows"
  [(ngModel)]="valueModel.value"
  [pectinFocusBinding]="valueModel.focusContext"
  [disabled]="disabled"
></textarea>
