import {Component, Input} from '@angular/core';
import {DisposableComponent, PlaceRouter} from '@coolon/angular-ui-powerups';
import {Command, Dsl, MutableValueModel, StoreInValueModel, Trace, TrackExternal} from '@coolon/pectin';
import {I18n} from '../../i18n';
import {placeDestinations} from "../../places/place-destinations";
import { Images } from '../../services/images/image-list';

@Component({
  selector: 'rps-mobile-page-header',
  templateUrl: './mobile-page-header.component.html',
  styleUrls: ['./mobile-page-header.component.scss'],
})
export class MobilePageHeaderComponent extends DisposableComponent {

  readonly i18n = I18n.components.mobilePageHeader;
  readonly Images = Images;

  readonly width = 400;
  readonly logoStyle = {
    width: `${this.width}px`,
    height: `${this.width / Images.LOGO.aspectRatio}px`,
    aspectRatio: Images.LOGO.aspectRatioAsCss
  }

  @Input()
  @TrackExternal()
  toggleMenuCommand: Command<void>;

  readonly homePlace = placeDestinations.home;

  constructor(private readonly placeRouter: PlaceRouter) {
    super();
    const {chain} = Dsl.disposeWith(this);
  }

  goHome() {
    this.placeRouter.navigate(this.homePlace.createVisit());
  }
}
