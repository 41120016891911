<div class="content" spaLibMarkdown [markdown]="testimonial.content()">
</div>
<div class="author" *ngIf="author$.value">{{ author$.value }}</div>
<a *ngIf="website$.value" [href]="website$.value">{{ website$.value }}</a>
<div class="ornament">
  <svg xmlns="http://www.w3.org/2000/svg"
       x="0px" y="0px"
       viewBox="0 0 100 100">
    <path d="M94.8,57.3c-1.3-8.7-8.7-14.9-17.1-15.3c2.1-7.8,7.2-10.9,13.4-12.7c0.6-0.2,0.5-0.8,0.5-0.8l-1-6.2c0,0-0.1-0.5-0.9-0.4  c-21.4,2.4-35.9,18.7-33.2,38.5C59,74.1,69.7,79.4,79.6,78C89.5,76.4,96.3,67.2,94.8,57.3L94.8,57.3z M26.5,42  c2-7.8,7.3-10.9,13.4-12.7c0.6-0.2,0.5-0.8,0.5-0.8l-0.9-6.2c0,0-0.1-0.5-0.9-0.4C17.2,24.2,2.8,40.5,5.3,60.3  C7.9,74.1,18.7,79.4,28.5,78c9.9-1.6,16.7-10.8,15.1-20.7C42.3,48.5,35,42.3,26.5,42z"></path>
  </svg>
</div>
