import { Component, OnInit } from '@angular/core';
import { Dsl } from '@coolon/pectin';
import {
  Place,
  PlaceComponent,
} from '@coolon/angular-ui-powerups';
import { I18n } from '../../../i18n';
import { Images } from '../../../services/images/image-list';

@Place({
  path: 'rachel',
  data: {
    nav: I18n.places.about.rachel.nav,
    seo: I18n.places.about.rachel.seo
  },
  authenticate: true
})
@Component({
  selector: 'rps-rachel',
  templateUrl: './rachel.component.html',
  styleUrls: ['./rachel.component.scss'],
})
export class RachelComponent extends PlaceComponent implements OnInit {

  readonly i18n = I18n.places.about.rachel;
  readonly Images = Images;

  constructor() {
    super();
    const {chain} = Dsl.disposeWith(this);
  }

  ngOnInit() {
  }

}
