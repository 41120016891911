import {Component, Input} from '@angular/core';
import {DisposableComponent, PlaceComponent, PlaceRouter} from '@coolon/angular-ui-powerups';
import {Constructable, Dsl, ListModel, MutableValueModel, StoreInListModel, Trace, TrackExternal} from '@coolon/pectin';
import {I18n} from '../../i18n';
import {placeDestinations} from "../../places/place-destinations";
import {Images} from '../../services/images/image-list';

@Component({
  selector: 'rps-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent extends DisposableComponent {

  readonly i18n = I18n.components.pageHeader;
  readonly Images = Images;

  readonly width = 615;
  readonly logoStyle = {
    width: `${this.width}px`,
    height: `${this.width / Images.LOGO.aspectRatio}px`
  }

  @Input()
  @TrackExternal()
  places$: ListModel<Constructable<PlaceComponent>>;

  @Input()
  @StoreInListModel("places$")
  places: Constructable<PlaceComponent>[];

  readonly menuOpen$ = new MutableValueModel(false);

  readonly homePlace = placeDestinations.home;

  constructor(private readonly placeRouter: PlaceRouter) {
    super();
    const {chain} = Dsl.disposeWith(this);
  }

  @Trace()
  toggleMenu() {
    this.menuOpen$.value = !this.menuOpen$.value;
  }

  goHome($event?: MouseEvent) {
    $event?.preventDefault()
    this.placeRouter.navigate(this.homePlace.createVisit());
  }
}
