import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'spa-common-right-sidebar, [spa-common-right-sidebar]',
  templateUrl: './right-sidebar.component.html',
  styleUrls: ['./right-sidebar.component.scss'],
})
export class RightSidebarComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
