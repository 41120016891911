<spa-common-page-slice>
  <div class="flex flex-col md:flex-row md:gap-4">
    <section class="flex flex-col flex-1">
      <h1>{{title$.value}}</h1>
      <!--  Important: must not have whitespace to ensure the :empty styles are triggered (as whitespace is preserved) -->
      <div #contentContainer class="flex-1 content-container">
        <ng-content></ng-content>
      </div>
    </section>
    <img *ngIf="imageRef$.value" [src]="imageRef.src" 
         [width]="imageRef.width" [height]="imageRef.height" alt=""
         class="self-center mt-8 w-[300px] md:mt-0 image-frame"/>

  </div>
  <section *ngIf="showCallToAction" slice-footer>
    <p>
      For more information or to organise a trial lesson get in touch.
    </p>
    <rps-call-to-action-buttons></rps-call-to-action-buttons>
  </section>
</spa-common-page-slice>
