import {Component, OnInit} from '@angular/core';
import {Command, Dsl} from '@coolon/pectin';
import {
  Place,
  PlaceComponent, PlaceRouter,
} from '@coolon/angular-ui-powerups';
import {I18n} from '../../i18n';
import {Images} from "../../services/images/image-list";
import {placeDestinations} from "../place-destinations";

@Place({
  path: 'home',
  data: {
    nav: I18n.places.home.nav,
    seo: I18n.places.home.seo
  },
  authenticate: true
})
@Component({
  selector: 'rps-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends PlaceComponent implements OnInit {

  readonly i18n = I18n.places.home;
  readonly testimonials = I18n.places.testimonials.testimonials;

  readonly Images = Images;

  readonly singingLessonsHref = placeDestinations.singingLessons.pathSegment;
  readonly aboutRachelHref = [placeDestinations.about.pathSegment, placeDestinations.rachel.pathSegment].join("/");

  constructor() {
    super();
  }

  ngOnInit() {
  }

}
