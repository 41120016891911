import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {Router} from "@angular/router";
import {NavItem, PlaceDefinition, PlaceRouter} from "@coolon/angular-ui-powerups";
import {
  Dsl,
  GarbageCollector,
  ListModelChain,
  MutableValueModel,
  TransactionObserver,
  ValueModelChain
} from '@coolon/pectin';
import {isFunction} from 'lodash-es';
import {PageSeo} from './page-seo';
import {SeoServiceConfig} from "./seo-service-config";

// see https://www.optimizesmart.com/single-page-apps-in-google-analytics-4/
// see https://developers.google.com/tag-platform/tag-manager/web/datalayer#use_a_data_layer_with_event_handlers
class PageSummary {
  constructor(placeDefinitions: PlaceDefinition[]) {
  }
}

@Injectable({providedIn: 'root'})
export class SeoService extends GarbageCollector {

  private dataLayer: object[];
  private readonly config$ = new MutableValueModel<SeoServiceConfig>();
  private readonly placeDefinitions$: ListModelChain<PlaceDefinition>;
  readonly title$: ValueModelChain<string>;
  readonly description$: ValueModelChain<string>;
  readonly url$: ValueModelChain<string>;
  readonly pagePath$: ListModelChain<string>;

  constructor(@Inject(DOCUMENT) private readonly document: Document,
              private titleService: Title,
              private meta: Meta,
              placeRouter: PlaceRouter,
              router: Router
  ) {
    super();
    const {chain, when} = Dsl.disposeWith(this);
    // @ts-ignore
    this.dataLayer = document.defaultView["dataLayer"] as object[];
    this.placeDefinitions$ = chain(placeRouter.activeVisits$).pluck("placeDefinition");
    this.pagePath$ = this.placeDefinitions$
      .map(d => (d.getPlaceData("nav") as NavItem).title())
      .rejectNil()
      .reverse();

    const seoData$ = chain(this.placeDefinitions$)
      .last()
      .map(d => d.getPlaceData("seo") as PageSeo);

    const seoTitle$ = chain(seoData$).map(d => isFunction(d.title) ? d.title() : undefined);

    const pagePathTitle$ = this.placeDefinitions$
      .map(d => d.getPlaceData("nav") as NavItem)
      .map(nav => nav.title())
      .reduce(titles => {
        let config = this.config$.value;
        return titles.concat(config.siteName).join(config.pathSeparator);
      }, {
        dependsOn: this.config$,
        postponeWhile: chain(this.config$).isNil()
      });

    this.title$ = seoTitle$.otherwise(pagePathTitle$);

    this.description$ = seoData$.map(page => isFunction(page.description) ? page.description() : undefined);

    this.url$ = chain(this.placeDefinitions$).reduce(() => router.url);

    // const txo = new TransactionObserver({verbose: true}).onEvent(e => console.log(e.name, e.payload));
    // txo.observe('seoData$', seoData$);
    // txo.observe('this.title$', this.title$);

    when(this.title$).changes(title => this.updatePageTitle(title), {
      initialValue: "triggerChangeUnlessNil"
    });

    when(this.description$).changes(description => {
      this.updatePageDescription(description);
    }, {
      initialValue: "triggerChangeUnlessNil"
    });

    const canonicalUrl$ = chain(this.url$).map(url => {
      let config = this.config$.value;
      // Hack: special handling for home until AUP can handle a place with `path: ''`
      return url === "/home" ? `${config.canonicalUrlBase}/` : `${config.canonicalUrlBase}${url}`
    }, {
      dependsOn: this.config$,
      postponeWhile: chain(this.config$).isNil()
    });

    // const txo = new TransactionObserver({verbose: true}).onEvent(e => console.log(e.name, e.payload));
    // txo.observe('canonicalUrl$', canonicalUrl$);
    // txo.observe('this.pagePath$', this.pagePath$);
    // txo.observe('placeRouter.navigationSession$', placeRouter.navigationSession$);

    when(canonicalUrl$).changes(url => {
      const canonicalLink = this.document.querySelector("link[rel=canonical]");
      canonicalLink.setAttribute("href", url);
    }, {
      initialValue: "triggerChangeUnlessNil",
      postponeWhile: chain(this.config$).isNil()
    });

    when(placeRouter.navigationSession$).becomesFalsey(() =>{
      this.dataLayer.push({
        'event': 'virtualPageView',
        'virtualPageUrl': canonicalUrl$.value,
        'virtualPagePath': pagePathTitle$.value,
        'virtualPageTitle': this.title$.value,
      });
    })
  }

  configure(config: SeoServiceConfig) {
    this.config$.value = config;
  }

  // @Trace()
  private updatePageDescription(description: string) {
    this.meta.updateTag({
      name: 'description', content: description,
    });
  }

  // @Trace()
  private updatePageTitle(title: string) {
    this.titleService.setTitle(title);
  }

  // @Trace()
}
