<rps-page-title title="The Institute for Vocal Advancement (IVA)"></rps-page-title>
<spa-common-page-slice>

  <section spa-common-right-sidebar>
    <section>
      
      <p *spaLibMarkdown class="text-xl">
        The Institute for Vocal Advancement (IVA) is an organisation dedicated to providing world class singing training
        based on the latest research in vocal science.
      </p>

      <div *spaLibMarkdown class="mt-8">
        The IVA also provides education for other essential aspects of singing and performance in order to meet the needs
        and interests of singers and voice instructors worldwide.

        ### What is the IVA Technique?

        The IVA technique is based on 17th and 18th Century principles of singing developed by the Schola Cantorum. The
        goal is to train voices to sing in a free and natural manner, smoothly, from the bottom of the range to the top
        with no breaks or sudden changes in quality.

        ### How will it help me?

        Training in this technique will give you access to all dynamics, with tonal clarity and flexibility. It will also
        help you improve your range, endurance, stamina, breath control, resonance, vocal strength and vocal quality
        across all styles of music without fatigue or damage to the voice.

        ### Who runs the IVA?

        The IVA has been founded by a group of world-class teachers of singing who have over 100 years of combined
        experience training singers and teachers of singing.

        The founding members have dedicated themselves to learning the best method for training the voice, and to pass
        this knowledge on to others. These Master Teachers have worked with singers of every genre: From Rock and Pop
        musicians going out on major tours to leads on Broadway performing Musical Theatre.

        The IVA Master Teachers are responsible for providing and developing ongoing education for IVA student teachers
        and IVA certified teachers in private lessons, webinars, Master Classes and Teacher Trainings. They are evaluating
        and certifying IVA instructors of all certificates.
      </div>
      
      <p class="mt-8">
        <a href="https://www.vocaladvancement.com/" class="button" buttonStyle="raised">Visit the IVA website...</a>
      </p>
      
    </section>


    <section sidebar>
      
      <div class="logo">
        <img [src]="Images.IVA_LOGO.src" [width]="Images.IVA_LOGO.width" [height]="Images.IVA_LOGO.height" alt="IVA Logo">
      </div>
      
      <div *spaLibMarkdown class="callout-grey mt-8">
        ### Why choose an IVA accredited teacher?

        Every voice is different and IVA instructors are specifically trained to quickly identify what's happening whether
        it be a coordination issue or a vocal problem you've been experiencing for some time (including vocal damage
        caused by years of abuse or incorrect training).

        Once diagnosed instructors design exercises tailored to move you towards better balance, range and healthier
        singing habits. These exercises encourage or 'trick' the nervous system and brain into believing the body is free
        from tension, therefore resulting in ease and effortlessness.

      </div>

    </section>

  </section>

  
</spa-common-page-slice>

