import { Component, OnInit } from '@angular/core';
import { Dsl } from '@coolon/pectin';
import {
  Place,
  PlaceComponent,
} from '@coolon/angular-ui-powerups';
import { I18n } from '../../i18n';
import {IvaTechniqueComponent} from "./iva-technique/iva-technique.component";
import {RachelComponent} from "./rachel/rachel.component";

@Place({
  path: 'about',
  data: {
    nav: I18n.places.about.nav,
    // seo: I18n.places.about.seo,
  },
  children:[
    RachelComponent,
    IvaTechniqueComponent
  ],
  defaultChild: RachelComponent,
  authenticate: false
})
@Component({
  selector: 'rps-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent extends PlaceComponent implements OnInit {

  readonly i18n = I18n.places.about;

  constructor() {
    super();
    const {chain} = Dsl.disposeWith(this);
  }

  ngOnInit() {
  }

}
