<rps-page-title title="Student Concerts">
  <p class="text-xl" *spaLibMarkdown>
    My concerts are specially designed to help students progressively develop their performing
    experience and level of skill, all within a friendly, encouraging and safe environment.
  </p>

  <ng-container *spaLibMarkdown>
    I have a **team of highly experienced** musicians, singers, sound engineers, and stage crew ready to create
    a magical and uplifting experience for each performer. This is a full, immersive experience designed to
    support students in their journey towards more confidence and solidifying of skill.

  </ng-container>
  
</rps-page-title>
<spa-common-page-slice>
  <section>

    <ng-container *spaLibMarkdown>

      ### Gallery
      
      Take a look at some of the amazing moments we have had the privilege of capturing!
    </ng-container>

    <div class="gallery">
<!--      <img *ngFor="let item of images" [src]="item.src" -->
<!--           [width]="item.width" [height]="item.height" alt="" [style]="{'&#45;&#45;ratio': item.aspectRatioAsCss}">-->
      <div class="row" *ngFor="let row of imageRows">
        <!-- Note: the image can be null to pad out the last row  -->
        <div class="item" *ngFor="let item of row" [style]="{'--ratio': item?.aspectRatioAsCss ?? '1/1'}">
          <img *ngIf="item" [src]="item.src">
        </div>
      </div>
    </div>
  </section>
</spa-common-page-slice>

<spa-common-page-slice>
  <section class="grid grid-cols-1 md:grid-cols-3 gap-8 align-top py-16 border-t">
    <spa-common-testimonial *ngFor="let testimonial of testimonials"
                            [testimonial]="testimonial"
    ></spa-common-testimonial>
  </section>
</spa-common-page-slice>
