import {Component, CUSTOM_ELEMENTS_SCHEMA, OnInit} from '@angular/core';
import {Dsl} from '@coolon/pectin';
import {
  Place,
  PlaceComponent,
} from '@coolon/angular-ui-powerups';
import {Testimonial} from "@spa/common";
import {I18n} from '../../i18n';

@Place({
  path: 'testimonials',
  data: {
    nav: I18n.places.testimonials.nav,
    seo: I18n.places.testimonials.seo,
  },
  authenticate: true
})
@Component({
  selector: 'rps-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss'],
})
export class TestimonialsComponent extends PlaceComponent implements OnInit {

  readonly i18n = I18n.places.testimonials;

  readonly testimonials: Testimonial[];

  constructor() {
    super();
    const {chain} = Dsl.disposeWith(this);
    this.testimonials = Object.keys(this.i18n.testimonials)
      .map(key => this.i18n.testimonials[key]);
  }

  ngOnInit() {
  }

}
