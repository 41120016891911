import { Injectable } from '@angular/core';

import { marked } from 'marked';
import { markdownPillExtension } from './pill-plugin';

@Injectable()
export class MarkdownService {

  constructor() {
  }

  render(content: string) {
    return marked.parse(content, {
      smartLists: true
    });
  }

  renderInline(content: string) {
    return marked.parseInline(content);
  }

  usePillExtension(renderer?: (content: string) => string) {
    marked.use({
      extensions: [markdownPillExtension(renderer ?? (text => `<span class='spa-lib-pill'>${text}<span>`))]
    });
  }
}


