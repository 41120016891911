<spa-common-page-slice padding="none">
  <section>
    <img [src]="Images.MICROPHONE.src" sizes="100vw"
         [width]="Images.MICROPHONE.normalisedWidth"
         [height]="Images.MICROPHONE.normalisedHeight"
         alt=""
         (click)="goHome()">
    <a class="title" href="/">
      <img [src]="Images.LOGO.src" sizes="100vw"
           [style]="logoStyle"
           alt="Rachel Pietsch Vocal Studios"
           (click)="goHome()">
    </a>
  </section>
</spa-common-page-slice>
