import {CommonModule} from "@angular/common";
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {
    AngularUiPowerupsModule,
    ComponentsModule as AupComponentsModule,
    InteractionModule,
    PlaceModule
} from "@coolon/angular-ui-powerups";
import {ImgixAngularModule} from "@imgix/angular";
import {CommonModule as SpaCommonModule} from '@spa/common';
import {ComponentsModule} from "../components/components.module";
import {AboutComponent} from './about/about.component';
import {IvaTechniqueComponent} from "./about/iva-technique/iva-technique.component";
import {PricingAndPoliciesComponent} from './pricing-and-policies/pricing-and-policies.component';
import {RachelComponent} from "./about/rachel/rachel.component";
import {ContactComponent} from './contact/contact.component';
import {HomeComponent} from './home/home.component';
import {SingingLessonsComponent} from './singing-lessons/singing-lessons.component';
import {SpeechTrainingComponent} from "./speech-training/speech-training.component";
import {StudentConcertsComponent} from './student-concerts/student-concerts.component';
import {TestimonialsComponent} from './testimonials/testimonials.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    HomeComponent,
    SingingLessonsComponent,
    TestimonialsComponent,
    AboutComponent,
    PricingAndPoliciesComponent,
    ContactComponent,
    StudentConcertsComponent,
    RachelComponent,
    SpeechTrainingComponent,
    IvaTechniqueComponent
  ],
    imports: [
        AupComponentsModule,
        InteractionModule,
        ComponentsModule,
        SpaCommonModule,
        PlaceModule,
        CommonModule,
        ImgixAngularModule,
        AngularUiPowerupsModule,
    ],
  providers: [],
})
export class PlacesModule {
  constructor() {
  }
}
