import {Component, Input} from '@angular/core';
import {PlaceRouter} from "@coolon/angular-ui-powerups";
import {Dsl, MutableValueModel, StoreInValueModel, TrackExternal, ValueModel} from '@coolon/pectin';
import {collapseAnimation} from "angular-animations";
import {I18n} from '../../../i18n';
import {NavContainer} from "../nav-container.component";

@Component({
  selector: '[spa-common-nav-stack]',
  templateUrl: './nav-stack.component.html',
  styleUrls: ['./nav-stack.component.scss'],
  animations: [collapseAnimation({anchor: 'hide', duration: 100})],
})
export class NavStackComponent extends NavContainer {

  readonly i18n = I18n.components.nav.navStack;

  @Input()
  @TrackExternal()
  open$: MutableValueModel<boolean>;

  @Input()
  @StoreInValueModel("open$")
  open: boolean;

  constructor(placeRouter: PlaceRouter) {
    super(placeRouter);
    const {chain} = Dsl.disposeWith(this);
  }

  get hideNav() {
    return !this.open;
  }

  toggleMobileOpen() {
    this.open = !this.open;
  }

}
