<ng-container *ngFor="let placeDef of placeDefinitions$.value">

  <ng-container *ngIf="placeDef.children.length > 0">
    <a class="place-link-parent">{{getNavData(placeDef).title()}}</a>
    <section>
      <a spa-common-place-link *ngFor="let childDef of placeDef.children"
         [parentPlaceDefinitions]="[placeDef]"
         [placeDefinition]="childDef"
      ></a>
    </section>
  </ng-container>

  <ng-container *ngIf="placeDef.children.length === 0">
    <a spa-common-place-link [placeDefinition]="placeDef"></a>
  </ng-container>

</ng-container>

