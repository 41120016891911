import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {GarbageCollector} from '@coolon/pectin';
import {PageNotFoundComponent} from "@spa/common";
import {placeRoutes} from './places/place-routes';
import {PlacesModule} from './places/places.module';

const routes: Routes = [
  ...placeRoutes,
  {path: '', redirectTo: "/home", pathMatch: "full"},
  {path: '404', component: PageNotFoundComponent},
  {path: '**', redirectTo: "404"}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      scrollPositionRestoration: 'enabled',
    }),
    PlacesModule,
  ],
  exports: [RouterModule]
})
export class AppRoutingModule extends GarbageCollector {

}
