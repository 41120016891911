import {InjectionToken} from "@angular/core";

export const CONTACT_FORM_CONFIGURATION = new InjectionToken<ContactFormConfiguration>("CONTACT_FORM_CONFIGURATION")

export interface ContactFormConfiguration {
  submissionEndpoint: string;
  enableFormSubmission: boolean;
  phoneNumber: string;
  emailAddress: string;
  confirmationMessage: string;
  honeyPot?: string;
  recaptchaSiteKey: string;
}
