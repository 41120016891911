import {Component, Directive} from '@angular/core';
import {DisposableComponent, NavItem, PlaceDefinition, PlaceRouter, Visit} from "@coolon/angular-ui-powerups";
import {Trace} from "@coolon/pectin";

// @Component({
//   selector: 'spa-common-nav-container',
//   template: '',
//   styleUrls: [],
// })
@Directive()
export abstract class AbstractNavComponent extends DisposableComponent {

  constructor(protected readonly placeRouter: PlaceRouter) {
    super();
  }

  public getNavData(d: PlaceDefinition):NavItem {
    return d.getPlaceData("nav") as NavItem;
  }

  @Trace()
  navigate($event: Event, ...placeDefPath: PlaceDefinition[]) {
    $event.preventDefault();
    this.placeRouter.navigate(placeDefPath.map(d => new Visit(d)));
  }



}
